import React from 'react';
import styles from './TopHeader.module.css';
import logo from '../../assets/Logo.jpg';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate


const handleDownload = () => {
  // Spécifiez l'URL de votre document PDF
  const pdfUrl = '/ABS_UserGuide_1_1_0.pdf';
  window.open(pdfUrl, '_blank');
};

function TopHeader({ onPageChange }) {
  const navigate = useNavigate();
  return (
    <header className={styles.TopHeader}>
      <div className={styles.navContainer}>
        <img src={logo} alt="Logo" />
        <button onClick={() => navigate('/')}>Accueil</button>
      </div>
      <div className={styles.navContainer}>
        <button  onClick={handleDownload} >Documentation</button>
        <button onClick={() => navigate('/contact')}>Contact</button>
      </div>
    </header>
  );
}

export default TopHeader;
