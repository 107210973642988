import Address from './Address';
import Classification from './Classification';
import Type from './Type';
import Group from './Group';
import Register from './Register';
import Document from './Document';
import FinancialData from './FinancialData';

class Vendor {
  constructor(data) {
    this.Id = data.Id;
    this.ExternalId = data.ExternalId;
    this.CommercialName = data.CommercialName;
    this.Type = data.Type;
    this.Classification = data.Classification;
    this.Freelance = data.Freelance;
    this.GroupName = data.GroupName;
    this.Group = data.Group;
    this.ActivityDescription = data.ActivityDescription;
    this.Register = data.Register;
    this.Addresses = data.Addresses.map(addr => new Address(addr));
    this.Documents = data.Documents.map(doc => new Document(doc));
    this.FinancialData = data.FinancialData;
  }
}

export default Vendor;
