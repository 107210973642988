import React from 'react';
import styles from './Button.module.css';
import LoadingIcon from './Loading/Loading'

const Button = ({ text, onClick, loading }) => {
  return (
    <button onClick={onClick} className={styles.button} disabled={loading}>
      {text}
      {loading && <LoadingIcon />}
    </button>
  );
};

export default Button;
