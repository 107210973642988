import React, { useState } from "react";
import styles from "./ExportListeXls.module.css";
import exportListe from "../../../assets/exportListe.svg"
import ok from "../../../assets/verifier.png"

function ExportListeXls({loadState, onclick, numberResults}) {


  return (
    <div className={styles.container} onClick={onclick}>
        <div className={styles.zindex0} >
          <img src={exportListe} alt="Exporter les submits pour la liste" className={`${(loadState === "loading" || numberResults < 1) ? styles.transparent : ''}`}/>
        </div>
        
        {loadState === "loaded" && <img src={ok} alt="fichier téléchargé" className={styles.zindex1}/> }

        {loadState === "loading" && (
        <div className={styles.center}>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
        </div>
        )}

    </div>

  );
}

export default ExportListeXls;
