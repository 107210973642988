import React, { useState } from "react";
import styles from "./OpenMessages.module.css";
import bulleMessage from '../../../assets/Subtract.svg';

function OpenMessages({loadState, onclick}) {


  return (
    <div className={styles.container} onClick={onclick}>
        <div className={styles.zindex0} >
          <img src={bulleMessage} alt="Afficher les mails" className={`${loadState === "loading" ? styles.transparent : ''}`}/>
        </div>
        
        {loadState === "loading" && (
        <div className={styles.center}>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
        </div>
        )}

    </div>

  );
}

export default OpenMessages;
