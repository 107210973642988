import { Root, Request, RequestStatus, Vendor, Classification, Type, Group, Register, APE, Regimesocial, Address, Country, Document, DocType, FinancialData, ReglementCondition, ReglementMode, TVA } from './../../models';
import { saveDentsuJsonFile, GetFilesDentsu } from './../../Services/demandes.jsx';
import JSZip from 'jszip';



// Root, Request, Vendor, Classification, Type, Group, Register, APE, Regimesocial, Address, Country, Document, DocType, FinancialData, ReglementCondition, ReglementMode, TVA

export function dateFormatDentsu () {
    let date = new Date();
    let stringDate = date.getUTCFullYear() + '-' +
        String(date.getUTCMonth() + 1).padStart(2, '0') + '-' +
        String(date.getUTCDate()).padStart(2, '0') + 'T' +
        String(date.getUTCHours()).padStart(2, '0') + ':' +
        String(date.getUTCMinutes()).padStart(2, '0') + ':' +
        String(date.getUTCSeconds()).padStart(2, '0') + '.' +
        String(date.getUTCMilliseconds()).padStart(3, '0');
        return stringDate
    }




export function formatDate ( dateString ) {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleDateString('fr-FR', options);
}


