import React from 'react';
import { Navigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const authItem = sessionStorage.getItem('i');
    const authValue = '0d15924c-e5eb-4acb-b380-5d65a2fee207';

    if (authItem === authValue) {
      return <WrappedComponent {...props} />;
    } else {
      return <Navigate to="/" />;
    }
  };
};

export default withAuth;
