import React, {useEffect, useState, useCallback} from "react";
import styles from "./DemandeCarat.module.css";
import Filter_svg from '../../assets/setting.svg';
import ReturnButton from '../../assets/fleche-gauche.png';
import FilterCarat from '../Filter/FilterCarat';
import OneDemandeCarat from '../OneDemandeCarat/OneDemandeCarat';
import { GetDemands_Dentsu, ExportExcel } from '../../Services/demandes';
import Card from '../CaratCard/Card'
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import { useNavigate } from 'react-router-dom'; // Importez useNavigate
import withAuth from '../HOC/WithAuth';
import ExportListeIcon from '../AnimIcons/ExportListeXls/ExportListeXls'

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleDateString('fr-FR', options);
}

function DemandeCarat({onPageChange}) {
    const navigate = useNavigate();
    const [loadExportListe, setLoadExportListe] = useState('')
    const [showFilter, setShowFilter] = useState(false);
    // const [filterData, setFilterData] = useState({});
    const [openOneDemande, setOpenOneDemande] = useState(false);
    const [selectedDemande, setSelectedDemande] = useState(null);
    const [data, setData] = useState(null);
    const [champs, setChamps] = useState(null);
    const [combos, setCombos] = useState(null);
    const [doublon, setDoublon] = useState(false)  
    const [detailId, setDetailId] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')
    const [nombreResult, setNombreResult] = useState("")
    const [filterStatus, setFilterStatus] = useState("")
    const [loadingData, setLoadingData] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)
    const [role, setRole] = useState(sessionStorage.getItem('role'))
    const [request, setRequest] = useState({
      user: {
        email: sessionStorage.getItem('currentMail'),
      },
      search: {
        vendor: "",
        object: "",
        status: "Submit",
        ordre: "",
        debut: "",
        fin: "",
        number: 0,
      },
    });

    useEffect(() => {
      const fetchData = async () => {
        try {
          setRequest({
            user: {
              email: sessionStorage.getItem('currentMail'),
            },
            search: {
              vendor: "",
              object: "",
              status: "Submit",
              ordre: "",
              debut: "",
              fin: "",
              number: 0,
            },
          });
          setLoadingData(true)

          const reponse = await GetDemands_Dentsu(request);

          if (!reponse.ok) {
            const message = `An error has occurred: ${reponse.status}`;
            throw new Error(message);
          }
          const data = await reponse.json();
          const results = JSON.parse(data);

          setChamps(results.ChampsJsonDentsu);
          setData(results.results.demandeslist);
          setCombos(results.combos);
          setNombreResult(results.results.demandeslist.length)
          setFilterStatus("Submit")
          setLoadingData(false)

          if (results.results.demandeslist.length > 0) {
            setShowFilter(false);
          }
          

        } catch (error) {
          console.error('Erreur lors de la récupération des données :', error);
        }
      };
  
      fetchData();
    }, []); // Le tableau vide assure que l'effet ne s'exécute qu'une seule fois
  

  
    const handleFilterClose = () => {
        setShowFilter(false);
    }

    const exportListe = () => {

      if(nombreResult < 1){
        return;
      }

      let request = {
        user: {
          email: sessionStorage.getItem("currentMail")
        },
        demandes: data
      };
    
      setLoadExportListe('loading')
      ExportExcel(request, "dentsu")
        .then(response => {
          if (!response.ok) {
            throw new Error(`An error has occurred: ${response.status}`);
          }
          return response.json();
        })
        .then(resultdata => {
          var results = JSON.parse(resultdata);
          console.log("resultat de la requete : ");
          console.log(results);
    
          let fichierEnBase64 = results.results.fileslist[0].filecontent;
          let filename = results.results.fileslist[0].filename;
          let mimetype = results.results.fileslist[0].mimetype;
    
          const downloadFile = (fichierEnBase64, filename, mimetype) => {
            const file = fileBuilt(fichierEnBase64, mimetype);
    
            // Download du fichier
            const element = document.createElement('a');
            element.href = URL.createObjectURL(file);
            element.download = filename;
            document.body.appendChild(element);
            element.click();
          };
    
          downloadFile(fichierEnBase64, filename, mimetype);
          setLoadExportListe('loaded');
        })
        .catch(error => {
          console.error("Erreur lors de l'exportation :", error);
        });
    };
    

    const handleFilterOpen = () => {
      setShowFilter(true);
    }

    const ReturnOnClick = () => {
      navigate('/search');
    }  

    const demandeClick = (demande) => {
      // setSelectedDemande(demande);
      // setOpenOneDemande(true);
      const jsonFile = decodeURIComponent(escape(atob(demande.detailslist[0].jsonfile)));
      const parsedJson = JSON.parse(jsonFile);

      setSelectedDemande(parsedJson);

      setSelectedStatus(demande.detailslist[0].status)
      setDetailId(demande.detailslist[0].id)
      setOpenOneDemande(true);
      setDoublon(demande.detailslist[0].doublonSiret)
    }

    const backToDemande = () => {
      setOpenOneDemande(false);
    }

    const handleFilterIcon = () => {
      if (showFilter == true){
        setShowFilter(false)
      }
      else{
        setShowFilter(true)
      }
    }


    const updateResultsList = async () => {
      setLoadingData(true)
      setLoadExportListe('')
      setNombreResult('')
      const reponse = await GetDemands_Dentsu(request);

      if (!reponse.ok) {
          const message = `An error has occurred: ${reponse.status}`;
          throw new Error(message);
      }else{
        const data = await reponse.json();
        const results = JSON.parse(data);
  
        setChamps(results.ChampsJsonDentsu);
        setData(results.results.demandeslist);
        setCombos(results.combos);
        setNombreResult(results.results.demandeslist.length)
        setLoadingData(false)
  
        if(results.results.demandeslist.length > 0){
          setShowFilter(false)
        }
      }
  };


    const handleFilterChange = async (vendor, status, tri, nombreResultat) => {
      setSearchLoading(true)
      setFilterStatus(status)
      setNombreResult('')
      setLoadExportListe('')
      if (status == "Completed" && nombreResultat == 0 ){
        nombreResultat = 50
      }

      const requete = {
                        "user": {
                            "email": sessionStorage.getItem('currentMail'),
                        },
                        "search": {
                            "vendor": vendor,
                            "object": "",
                            "status": status,
                            "ordre": tri,
                            "debut": "",
                            "fin": "",
                            "number": parseInt(nombreResultat)
                        }
                    };

      setRequest(requete)

      setLoadingData(true)
      const reponse = await GetDemands_Dentsu(requete);
      if (!reponse.ok) {
          const message = `An error has occurred: ${reponse.status}`;
          throw new Error(message);
      }
      const data = await reponse.json();
      const results = JSON.parse(data);
      setChamps(results.ChampsJsonDentsu);
      setData(results.results.demandeslist);
      setCombos(results.combos);
      setNombreResult(results.results.demandeslist.length)
      setLoadingData(false)
      setSearchLoading(false)
      if(results.results.demandeslist.length > 0){
        setShowFilter(false)
      }

  };

  
  let tooltipElem;
  function infoBulle(event){
    let target = event.target;
    
      // if we have tooltip HTML...
      let tooltipHtml = target.dataset.tooltip;
      if (!tooltipHtml) return;
      // ...create the tooltip element

      tooltipElem = document.createElement('div');
      tooltipElem.className = styles.tooltip;
      tooltipElem.innerHTML = tooltipHtml;
      document.body.append(tooltipElem);
  
      // position it above the annotated element (top-center)
      let coords = target.getBoundingClientRect();
      let left = coords.left + (target.offsetWidth - tooltipElem.offsetWidth) / 2;

      if (left < 0) left = 0; // don't cross the left window edge
      let top = coords.top - tooltipElem.offsetHeight - 5;
      if (top < 0) { // if crossing the top window edge, show below instead
        top = coords.top + target.offsetHeight + 5;
      }

      tooltipElem.style.left = left + 'px';
      tooltipElem.style.top = top + 'px';
    }; 
    document.onmouseout = function(e) {

      hideToolTip()

    };
    

function hideToolTip() {
  if (tooltipElem) {
    tooltipElem.remove();
    tooltipElem = null;
  }
}


const fileBuilt = (fichierEnBase64, mimetype) => {
  // Création du fichier
  let filecontent = atob(fichierEnBase64)
  var buffer = new ArrayBuffer(filecontent.length);
  var bufferView = new Uint8Array(buffer);
  
  for (var i = 0; i < filecontent.length; i++) {
    bufferView[i] = filecontent.charCodeAt(i);
  }
  const file = new Blob([bufferView], {type: mimetype}); 
return file;
}
      return (
        <div className={styles.root}>
            <div className={styles.pageTitle}>

              <h2>{nombreResult} {nombreResult > 1 && <span> Demandes</span>}{nombreResult < 2 && <span></span>} {filterStatus}
              </h2>
            </div>

            <div className={styles.body}>

              <div className={styles.menuLeft}>
                {role.includes('media-servicing') && <img src={ReturnButton} alt="Retour en arrière" className={styles.retour} onClick={ReturnOnClick} />}
                <img src={Filter_svg} alt="Carat" className={styles.filterImg} onClick={handleFilterIcon}/>
                {filterStatus !== "Structural Error" && 
                    (<div>
                  <ExportListeIcon loadState={loadExportListe} onclick={exportListe} numberResults={nombreResult} className={styles.exportListeIcon} />
                </div>)}
              </div>

              {!openOneDemande &&
              <div className={styles.container}>

                {loadingData && <LoadingIcon/>}
                {showFilter && <FilterCarat filterChange={handleFilterChange} filterClose={handleFilterClose} loadingState={searchLoading}/>}
                  
                <div className={styles.demandes}>
                  
                  {data && data.map((demande, index) => {
                          return (
                            <div>
                              <Card key={index} vendorLib={demande.vendorLib} demandeId={demande.id} date={demande.dateinit} status={demande.detailslist[0].status} type={demande.objet} vendorId={demande.vendorId} vendorType={demande.vendortype} chatOpen={() => demandeClick(demande)} demandeur={demande.demandeur} details={demande.detailslist} />
                            </div>
                          );
                        })}
                  {nombreResult == 0 && <p>Utilisez les filtres pour lancer ou modifier une recherche.</p>}

                </div>
                  

                  </div>
                }
                { openOneDemande && <OneDemandeCarat demande={selectedDemande} backToDemande={backToDemande} champs={champs} combos={combos} doublon={doublon} statutDemande={selectedStatus} detailId={detailId} updateResultsList={updateResultsList} />}
              </div>
          </div>
      );
}

export default withAuth(DemandeCarat);
