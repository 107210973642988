import React, { useState } from "react";
import styles from "./ContactPage.module.css";
import Button from "../Button/Button";
import { SendContactMessage } from "../../Services/demandes";
import LoadingIcon from './../LoadingIcon/LoadingIcon.jsx';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate

function ContactPage({onPageChange}) {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [tel, setTel] = useState("");
  const [nomPrenom, setNomprenom] = useState("");
  const [societe, setSociete] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const closePopup = () => {
    setShowPopup(false);
    navigate('/');
  };

const handleSubmit = async (event) => {
  event.preventDefault(); // Empêche le rechargement de la page

    const request = {
      user: {},
      email: {
        from: email,
        to: "tiwa.nguyen@easy-media.fr",
        subject: subject,
        body:
          body +
          " \r\n \r\n De : " +
          nomPrenom +
          " \r\n Téléphone : " +
          tel +
          " \r\n Société : " +
          societe,
      },
    };

    setLoading(true);

    try {

      const response = await SendContactMessage(request);
      if(response.ok){
        setShowPopup(true);
        setEmail("");
        setSubject("");
        setBody("");
        setTel("");
        setNomprenom("");
        setSociete("");

      } else {
        const messages = `An error has occurred: ${response.status}`;
        throw new Error(messages);
      }
      
    } catch (error) {
      console.error("Error saving response:", error);
      // Handle error display or other actions here
    }
  };

  return (
    <div className={styles.container}>
      {showPopup && (
          <div className={styles.popup}>
            <div className={styles.encartPopup}>
              <p>Message envoyé !</p><br/>
              <button onClick={closePopup}>Fermer</button>
            </div>
          </div>
      )}

      <form className={styles.form} onSubmit={handleSubmit}>
        <h1>Ecrivez-nous !</h1>
        <p>Une question ? Utilisez ce formulaire :</p>

        <div className={styles.containerChamps}>
        <div className={styles.champs}>
          <div className={styles.row}>
            <input
              className={styles.input}
              type="text"
              placeholder="Nom Prénom"
              value={nomPrenom}
              onChange={(e) => setNomprenom(e.target.value)}
            />
            <input
              className={styles.input}
              type="text"
              placeholder="Societe"
              value={societe}
              onChange={(e) => setSociete(e.target.value)}
            />
          </div>

          <div className={styles.row}>
            <input
              className={styles.input}
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className={styles.input}
              type="tel"
              placeholder="Téléphone"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />
          </div>

          <div className={styles.row}>
            <input
              className={styles.input}
              type="text"
              placeholder="Sujet"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
</div>
<div className={styles.champs}>
<textarea
          className={styles.textarea}
          placeholder="Votre message"
          value={body}
          onChange={(e) => setBody(e.target.value)}
        ></textarea>
          </div>
        
</div>

        <Button text="Envoyer"  type="submit" />
      </form>
      
    </div>
  );
}

export default ContactPage;
