import React from 'react';
import { Navigate } from 'react-router-dom';

const WithIdent = (WrappedComponent) => {
  return (props) => {
    const authItem = sessionStorage.getItem('i');
    const authValue = '7c156b41-d5c1-4b3b-90ce-d7c5ba4939a2';

    if (authItem === authValue) {
      return <WrappedComponent {...props} />;
    } else {
      return <Navigate to="/" />;
    }
  };
};

export default WithIdent;
