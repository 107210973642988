import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';
import styles from './Dashboard.module.css';
import send_fill from '../../assets/Send_fill.svg';
import search from '../../assets/Search_fill.svg';
import PopUpSend from '../PopUp/PopUpSend';
import PopUpConnexion from '../PopUp/PopUpConnexion';
import { GetDashboard } from '../../Services/demandes';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate

function Dashboard({ isLoading }) {
  const navigate = useNavigate();
  const [isRequesting, setIsRequesting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [prenom, setPrenom] = useState("");
  const [newDemands, setNewDemands] = useState("")
  const [enCours, setEncours] = useState("")
  const [traite, setTraite] = useState("")
  const [loading, setLoading] = useState(true)

  const handleCloseRequesting = () => {
    setIsRequesting(false);
  };

  const handleCloseSearching = () => {
    setIsSearching(false);
  };

  const handleDemandeClick = () => {
    setIsSearching(false);
    setIsRequesting(true);
  };

  const handleRechercheClick = () => {
    setIsRequesting(false);
    setIsSearching(true);
  };

  // const handleChangePage = (page) => {
  //   onPageChange(page);
  // };

  useEffect(() => {
    const getDashboardData = async () => {
      setLoading(true)

      isLoading(true)
      
      try {  
        const response = await GetDashboard(sessionStorage.getItem('currentMail'));

        if (!response.ok) {
            const messages = `An error has occurred: ${response.status}`;
            throw new Error(messages);
        }
        const data = await response.json();
        const result = JSON.parse(data);
        setPrenom(result.user.prenom);
        setNewDemands(result.dashboard.initial);
        setEncours(result.dashboard.enCours);
        setTraite(result.dashboard.traite);
        setTimeout(() => {
          isLoading(false); // Indique que le chargement est terminé
          setLoading(false)
        }, 700); 

      } catch (error) {
        console.error("Error saving response:", error);
        // Handle error display or other actions here
      }
  };

  getDashboardData();
}, []);

    return (
        <div className={styles.divContainer}>

          {!loading &&
            <div className={styles.contenu}>
              <h1>Bonjour {prenom},</h1>
              <p>Le résumé sur les demandes</p>
              <ul>
                  <li><strong>Demandes en cours</strong> de traitement : <strong>{enCours}</strong></li>
                  <li><strong>Nouvelles demandes</strong> en attente : <strong>{newDemands}</strong></li>
                  <li><strong>Demandes traitées</strong> depuis votre dernière connexion : <strong>{traite}</strong></li>
              </ul>
              <p>Vous pouvez modifier les filtres de recherche pour accéder à d’autres résultats.</p>
            </div>
          }
        </div>
    );

}

export default Dashboard;
