import React, { useState } from 'react';
import styles  from './Demande.module.css';
import subtract from '../../assets/Subtract.svg';
import xls from '../../assets/Xls.svg';
import folder from '../../assets/folder.png';
import { ExportExcel, GetFilesAndLogs_Abs, UpdateStatusAbs } from "../../Services/demandes";
import JSZip from 'jszip';
import PopupAlert from '../PopUp/PopupAlert/PopupAlert';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate
import DownloadFolder from '../AnimIcons/DownloadFolder/DownloadFolder';
import DownloadExcelFile from '../AnimIcons/DownloadExcelFile/DownloadExcelFile';
import OpenMessages from '../AnimIcons/OpenMessages/OpenMessages';

function Demande ({ key, name, number, chatOpen, date, status, demandeur, objet, countEmail, updateResulstList }) {
const [statut, setStatut] = useState(status); // valeur initiale
const [demandUser, setDemandUser] = useState(demandeur)
const [messageAlert, setMessageAlert] = useState("");
const [showPopupAlert, setShowPopupAlert] = useState("");
const [role, setRole] = useState(sessionStorage.getItem("role"))
const [loadstate, setLoadstate] = useState('')
const [xlsloadstate, setXlsLoadstate] = useState('')
const [loadMailState, setLoadMailState] = useState('')

const navigate = useNavigate();

const exportXls = async ()  => {

  let contexte = "";
  if(!sessionStorage.getItem("currentMail").includes("dentsu")){
    contexte = "abs";
  }else{
    contexte = "dentsu";
  }
  
  let request = {
    user: {
        email: sessionStorage.getItem("currentMail")
    },
    demandes:[
      {
        id:number,
        regiesupportlib:name,
        status:status,
        dateinit:date,
        demandeur:demandeur,
        objet:objet,
        countemails:countEmail
      }
    ]
};
  setXlsLoadstate('loading')
  const response = await ExportExcel(request, 'abs');

  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    setXlsLoadstate("")

    throw new Error(message);
  }

  const data = await response.json();
  const result = JSON.parse(data);
  let fichierEnBase64 = result.results.fileslist[0].filecontent
  let filename = result.results.fileslist[0].filename
  let mimetype = result.results.fileslist[0].mimetype 
  
const downloadFile = (fichierEnBase64, filename, mimetype) => {
  const file = fileBuilt(fichierEnBase64, mimetype)
// Download du fichier
  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
};

downloadFile(fichierEnBase64, filename, mimetype);
  setXlsLoadstate('loaded')
}

const handleClosePopup = () => {
  setShowPopupAlert(false)
  setMessageAlert("")
}

const openMails = () => {
  setLoadMailState("loading")
  chatOpen()
  setLoadMailState("")
}

const fileBuilt = (fichierEnBase64, mimetype) => {
    // Création du fichier
    let filecontent = atob(fichierEnBase64)
    var buffer = new ArrayBuffer(filecontent.length);
    var bufferView = new Uint8Array(buffer);
    
    for (var i = 0; i < filecontent.length; i++) {
      bufferView[i] = filecontent.charCodeAt(i);
    }
    const file = new Blob([bufferView], {type: mimetype}); 
  return file;
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleDateString('fr-FR', options);
}

const downloadFolder = async ()  => {
  setLoadstate('loading')
  const response = await GetFilesAndLogs_Abs(number);

  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();
  const result = JSON.parse(data);
  let files = result.results.fileslist;

  let zip = new JSZip();
  
  // Ajouter chaque fichier au ZIP
  files.forEach(fichier => {
    let fich = fichier.filecontent;

    if (fichier.filecontent.includes(";base64,")){
      let arrayBase64 = fichier.filecontent.split(";base64,");
      fich = arrayBase64[1]
    }

    zip.file(fichier.filename, fich, { base64: true });
  });

  // Générer le contenu du ZIP
  zip.generateAsync({ type: "blob" })
    .then(function (blob) {
      // Créer un objet URL pour le blob
      let url = URL.createObjectURL(blob);

      // Créer un lien pour télécharger le ZIP
      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Demande'+ number +'.zip');

      // Ajouter le lien à la page et cliquer dessus pour déclencher le téléchargement
      document.body.appendChild(link);
      link.click();
    });

    setLoadstate('loaded')

}


const updateStatus = async (event) => {
  let newValue = event.target.value

  const newStatus = newValue;
  setStatut(newStatus);
console.log('demande user : ' + demandUser)

  let request = {
    user: {
        email: sessionStorage.getItem("currentMail")
    },
    demande:
    {
      id:number,
      regiesupportlib:name,
      status:event.target.value,
      dateinit:date,
      demandeur:demandeur,
      objet:objet
    }    
  };
  console.log(request)
console.log("appel api")
  const response = await UpdateStatusAbs(request);

  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    setMessageAlert("Echec de la mise à jour du statut " + newValue)
    setShowPopupAlert(true)
    throw new Error(message);
  }
  else{
    setMessageAlert("Statut mis à jour. Nouveau statut : " + newValue)
    setShowPopupAlert(true)
    updateResulstList()
  }

}

  return (
    <div className={styles.container}>

    {showPopupAlert && <PopupAlert  message={messageAlert} handleClosePopup={handleClosePopup} />}


      <div className={styles.topTicket}> 
      <div className={styles.topLeftTicket}>
        <div className={styles.numeroDemande}>
          <span>#</span>
          <span id="number">{number}</span>
        </div>
        <div id="name" className={styles.name}>{name}</div>
      </div>
        <div className={styles.icons}>

          <div className={styles.folderImg}>
            <DownloadFolder  loadState={loadstate}  onclick={downloadFolder}/>
          </div>

          <div className={styles.xlsImg}>
            <DownloadExcelFile  loadState={xlsloadstate}  onclick={exportXls}/>
          </div>

          <div className={styles.voirMessages}>
            <OpenMessages loadState={loadMailState} onclick={openMails}/>
          </div>

        </div>

      </div>
        <p id="objet">{objet}</p>
        <p id="countEmail" hidden>{countEmail}</p>
      <hr></hr>
      <div className={styles.bottomTicket}>
        <p >{formatDate(date)}</p>


        { role.includes('media-servicing') && 
        <select className={styles.status} id="status" name="status" onChange={updateStatus} value={status}>
          <option value="Demande initiale">Demande initiale</option>
          <option value="Demande en cours">Demande en cours</option>
          <option value="Demande traitée">Demande traitée</option>
        </select>}
        {!role.includes('media-servicing') && <p>{status}</p>}

        <p id="demandeur"> {demandeur} </p>
      </div>
    </div>
  );
};

export default Demande;
