import ReglementCondition from './ReglementCondition';
import ReglementMode from './ReglementMode';
import Type_TVA_Appliquee from './TVA';

class FinancialData {
  constructor(data) {
    // Vérification de l'existence des propriétés avant l'initialisation
    if (!data.ReglementCondition || !data.ReglementMode || !data.Type_TVA_Appliquee) {
      throw new Error("Les propriétés ReglementCondition, ReglementMode et Type_TVA_Appliquee doivent être définies.");
    }
    this.ReglementCondition = data.ReglementCondition;
    this.ReglementMode = data.ReglementMode;
    this.Type_TVA_Appliquee = data.Type_TVA_Appliquee;
  }
}

export default FinancialData;
