import React from 'react';
import {  BrowserRouter as Router, useParams,  Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import TopHeader from './Component/TopHeader/TopHeader';
import HomePage from './Component/HomePage/HomePage';
import ContactPage from './Component/ContactPage/ContactPage';
import Footer from './Component/Footer/Footer';
import AskPage from './Component/AskPage/AskPage';
import SearchPage from './Component/SearchPage/SearchPage';
import SelectEmail from './Component/SelectEmail/SelectEmail';
import DemandeCarat from './Component/DemandeCarat/DemandeCarat';
import LoadingMailing from './Component/LoadingMailing/LoadingMailing';
import OneDemandeCarat from './Component/OneDemandeCarat/OneDemandeCarat';
import Dashboard from './Component/Dashboard/Dashboard';
import ErrorPage from './Component/ErrorPages/404/NotFound'; // Créez ce composant pour les erreurs

function App() {
  return (
    <div className="App">
      <TopHeader />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/documentation" element={<DocumentationPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/newDemand" element={<AskPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/demandeCarat" element={<DemandeCarat />} />
        <Route path="/LoginResponse/:uidValue" element={<SelectEmail />} />
        <Route path="/loadingMailing" element={<LoadingMailing />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

const DocumentationPage = () => {
  const filePath = 'ABS_UserGuide_1_1_0.pdf';
  const link = document.createElement('a');
  link.href = filePath;
  link.download = 'documentation.pdf';
  link.click();
  return <div>Downloading...</div>;
};

export default App;
