import React, { useState } from "react";
import styles from "./See.module.css";
import eye from '../../../assets/oeil.png';

function See({loadState, onclick}) {


  return (
    <div className={styles.container} onClick={onclick}>
        <div className={styles.zindex0} >
          <img src={eye} alt="Afficher" className={`${loadState === "loading" ? styles.transparent : ''}`}/>
        </div>
        
        {loadState === "loading" && (
        <div className={styles.center}>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
        </div>
        )}

    </div>

  );
}

export default See;
