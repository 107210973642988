import React, {useEffect, useState} from "react";
import styles from "./LoadingMailing.module.css";
import { GetOneDemandAbs } from "../../Services/demandes";
import Mailing from "../MailingFromUrl/MailingFromUrl";
import LoadingAnimation from "../LoadingIcon/LoadingIcon"
import { useNavigate } from 'react-router-dom'; // Importez useNavigate

function LoadingMailing() {
    const [mailingLoaded, setMailingLoaded] = useState(false);
    const [oneDemand, setOneDemand] = useState({});
    const navigate = useNavigate();
    


    const getDemandeData = async () => {
        console.log("dans getDemandeData : ")

        const demandeId = sessionStorage.getItem("demandeId");
        console.log("demandeId : " + demandeId)
        const response = await GetOneDemandAbs(demandeId);

        if (!response.ok) {
            const message = `An error has occurred: ${response.status}`;

            throw new Error(message);
        }else{
            const data = await response.json();
            const result = JSON.parse(data);
            console.log("B")

            console.log(result)

            if(result){
                setOneDemand(result);
                setMailingLoaded(true);
            }
        }
    };

    useEffect(() => {
        if (!mailingLoaded) {
            getDemandeData();
        } 
    }, []);




    return (
        <div className={styles.container}>


            {mailingLoaded && <Mailing fromSearchPage={false} oneDemand={oneDemand} updateData={getDemandeData}/>}
            {!mailingLoaded && <LoadingAnimation/>}
        </div>
    );
    }

export default LoadingMailing;