import React, { useState } from "react";
import styles from "./PopupAlert.module.css";
import cross_svg from "../../../assets/cross.svg";

function PopupAlert({ message, handleClosePopup}) {

  const close =()=>{
    console.log("dans close de la pop up")
    handleClosePopup();
  }

  console.log("dans popup alert")
  
  return (
    <div className={styles.container}>
      <div className={styles.popup}>
          <span className={styles.closeIcon} onClick={close}>
              <img src={cross_svg} alt="Close" className={styles.closeIconImg} />
          </span>
          <p>{message}</p>
      </div>
    </div>
  );
}

export default PopupAlert;
