class Status {
  constructor(data) {
    this.Id = data.Id;
    this.Name = data.Name;
    this.dateUpdate = data.dateUpdate;
    this.Raison = data.Raison;
  }
}

export default Status;
