import React, { useRef, useEffect } from "react";
import styles from "./BannerRgpd.module.css";
import Button from "../Button/Button";
import cross_svg from "../../assets/cross.svg"; // Assurez-vous que le chemin vers votre fichier cross.svg est correct

function BannerRgpd({ onClose }) {
  const popUpRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();  // Pour éviter le rechargement de la page
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);
    document.addEventListener("keydown", handleEnterKey);


    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
      document.removeEventListener("keydown", handleEnterKey);

    };
  }, [onClose]);

  return (
    <div className={styles.contenantPopup}>
      <div className={styles.popUp} ref={popUpRef}>
        <img
          src={cross_svg}
          alt="Fermer"
          className={styles.closeIcon}
          onClick={onClose}
        />
        <h3>Nous respectons votre vie privée</h3>
        <p>Nous utilisons des données locales pour faire fonctionner notre application et personnaliser votre expérience. Aucune donnée n'est utilisée à des fins marketing. En continuant, vous acceptez notre utilisation de ces données.</p>

        <div className={styles.divButton}>
          <Button text="Accepter" onClick={onClose} />
        </div>
      </div>
    </div>
  );
}

export default BannerRgpd;
