import React, { useState } from "react";
import styles from "./PopupConfirm.module.css";
import cross_svg from "../../../assets/cross.svg";
import Button from "../../Button/Button";

function PopupConfirm({ message, actionOnConfirm, actionOnClose }) {

  const confirm =()=>{
    console.log("dans confirm de la pop up")
    actionOnConfirm();
  }

  const cancel =()=>{
    console.log("dans cancel de la pop up")
    actionOnClose();
  }

  return (
    <div className={styles.container}>
      <div className={styles.popup}>
          <span className={styles.closeIcon} onClick={cancel}>
              <img src={cross_svg} alt="Close" className={styles.closeIconImg} />
          </span>
          <p>{message}</p>
          <div className={styles.buttons}>
            <div className={styles.button}>
              <Button text="Continuer"  onClick={confirm}/>
            </div>
            <div className={styles.button}>
              <Button text="Annuler"  onClick={cancel}/>
            </div>
          </div>
      </div>
    </div>
  );
}

export default PopupConfirm;
