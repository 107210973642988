import React, { useState } from "react";
import styles from "./DownloadExcelFile.module.css";
import excel from "../../../assets/Xls.svg"
import ok from "../../../assets/verifier.png"

function DownloadExcelFile({loadState, onclick}) {


  return (
    <div className={styles.container} onClick={onclick}>
        <div className={styles.zindex0} >
          <img src={excel} alt="télécharger le fichier" className={`${loadState === "loading" ? styles.transparent : ''}`}/>
        </div>
        
        {loadState === "loaded" && <img src={ok} alt="fichier téléchargé" className={styles.zindex1}/> }

        {loadState === "loading" && (
        <div className={styles.center}>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
        </div>
        )}

    </div>

  );
}

export default DownloadExcelFile;
