import React from 'react';
import styles from './NotFound.module.css';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className={styles.container}>
      <p className={styles.zoomArea}>Oups, vous êtes perdus ?</p>
      <section className={styles.errorContainer}>
        <span>4</span>
        <span><span className={styles.screenReaderText}>0</span></span>
        <span>4</span>
      </section>
      <Link to="/" className={styles.homeLink}>Revenez à la page d'accueil</Link>

    </div>
  );
};

export default NotFound;
