import React, { useState } from "react";
import styles from "./LoadingIcon.module.css";

function LoadingIcon({ message }) {
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.center}>
        <div className={styles.wave}></div>
        <div className={styles.wave}></div>
        <div className={styles.wave}></div>
        <div className={styles.wave}></div>
        <div className={styles.wave}></div>
        <div className={styles.wave}></div>

      </div>
    </div>
  );
}

export default LoadingIcon;
