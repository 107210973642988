

const apiUrl = process.env.REACT_APP_MODE === 'PROD' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_TEST ;
const apikey = process.env.REACT_APP_API_KEY

export async function CheckConnection(email) {
    const response = await fetch(`${apiUrl}/api/values/Login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
        body: JSON.stringify({ 
            user: {
                email: email
            }
        })
    });
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }
    else{
        
        sessionStorage.setItem('i', '7c156b41-d5c1-4b3b-90ce-d7c5ba4939a2')

    }
    const result = await response.json();
    var data = JSON.parse(result);
    return data;
}

export async function CheckConnectionHigh(email, password) {

    const response = await fetch(`${apiUrl}/api/values/Auth`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
        body: JSON.stringify({ 
            user: {
                email: email,
                pass: password
            }
        })
    });
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function GetEmails(uid) {
    const response = await fetch(`${apiUrl}/api/values/SelectEmails?uid=${uid}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        }
    });
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response;
}

export async function GetValidator(email) {
    const response = await fetch(`${apiUrl}/api/values/GetValidator?username=${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        }
    });
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }

    return response;
}