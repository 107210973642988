import React, { useRef, useEffect, useState } from "react";
import styles from "./PopUpSend.module.css";
import Button from "../Button/Button";
import { CheckConnection } from '../../Services/identification_api';
import cross_svg from "../../assets/cross.svg";
import { useNavigate } from 'react-router-dom'; // Importez useNavigate


function PopUpSend({ onClose, pageChange }) {
  const popUpRef = useRef();
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); 
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const [buttonLoading, setButtonLoading] = useState(false);


  const handleSendClick = async () => {
    setButtonLoading(true)
    let mail = document.getElementById("email").value;
    try {
      const response = await CheckConnection(mail);
      if (response.status.code === 200) {
        sessionStorage.setItem('currentMail', mail);
        sessionStorage.setItem('dataLogin', response.data);
        setButtonLoading(false)
        onClose();
        navigate('/newDemand')
      }
    } catch (error) {
      setButtonLoading(false)
      console.error(error.message);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();  // Pour éviter le rechargement de la page
        handleSendClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);
    document.addEventListener("keydown", handleEnterKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
      document.removeEventListener("keydown", handleEnterKey);
    };
  }, [onClose]);

  return (
    <div className={styles.contenantPopup}>

      <div className={styles.popUp} ref={popUpRef}>
        <img src={cross_svg} alt="Close" className={styles.closeIcon} onClick={onClose} />
        <h1>Votre identité</h1>
        <br/> <p>Merci de saisir votre email</p><br/>

        <div className={styles.inputDiv}>
          <label htmlFor="email"></label>
          <input type="email" id="email" name="email" placeholder="Email" value={email} onChange={handleEmailChange} required />
        </div>
        <div className={styles.divButton}>
          <Button text="Valider" onClick={handleSendClick} loading={buttonLoading} />
          {/*<Button text="Fermer" onClick={onClose} />*/}
        </div>
        <div className={styles.popUpContainer}></div>
      </div>
    </div>
  );
}

export default PopUpSend;
