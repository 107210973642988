import React, { useState, useEffect } from 'react';
import Demande from '../Demande/Demande';
import ExportListeIcon from '../AnimIcons/ExportListeXls/ExportListeXls';

import styles from './SearchPage.module.css';
import Mailing from '../Mailing/Mailing';
import Filter_svg from '../../assets/setting.svg';
import ExportListe from '../../assets/exportListe.svg'
import Filter from '../Filter/Filter';
import { ExportExcel, GetDemandsAbs, GetOneDemandAbs } from '../../Services/demandes';
import DemandeCarat from '../DemandeCarat/DemandeCarat';
import Dashboard from '../Dashboard/Dashboard';
import Button from '../Button/Button';
/*import LogoCarat from '../../assets/carat-logo.png';*/
import LogoCarat from '../../assets/carat-logo.svg';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate
import withAuth from '../HOC/WithAuth';

function SearchPage({onPageChange}) {
  const navigate = useNavigate();
  const [loadExportListe, setLoadExportListe] = useState('')
  const [showMailing, setShowMailing] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [result, setResult] = useState([{}]);
  const [oneDemand, setOneDemand] = useState([{}]);
  const [noData, setNoData] = useState(false);
  const [emailRole, setEmailRole] = useState('other');
  const [showDashboard, setShowDashboard] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const [filterStatus, setFilterStatus] = useState("")
  const [nombreResult, setNombreResult] = useState("")
  const [searchLoading, setSearchLoading] = useState(false)
  const [request, setRequest] = useState({
    user: {
        email: sessionStorage.getItem('currentMail')
    },
    search: {
        regiesupportlib: "",
        object: "",
        status: "",
        ordre: "",
        debut: "",
        fin: "",
        number: 0,
    },
});

  useEffect(() => {
    // Check email domain and set role accordingly only on initial 
    const email = sessionStorage.getItem('currentMail');
    const getEmailRole = () => {
      if (email) {
        if (email.endsWith('dentsu.com')) {
          sessionStorage.setItem('role', 'dentsu');
        } else if (email.endsWith('media-servicing.com')) {
          sessionStorage.setItem('role', 'media-servicing');
        } else {
          sessionStorage.setItem('role', 'other');
        }
      }
    };
    getEmailRole();
    setEmailRole(sessionStorage.getItem('role'));
  }, []);

  const demandeCaratOnClick = () => {
    navigate('/demandeCarat');
  }

  const handleLoading = (isLoading) => {
    setIsLoading(isLoading);
};

  const handleChatClose = () =>{
    setShowMailing(false);
  }

  const handleFilterChange = async (email, regiesupportlib, object, status, ordre, debut, fin, top) => {
    console.log("dans handle filter change : ")
    setIsLoading(setIsLoading)
    setFilterStatus(status)
    setLoadExportListe('')
    setNombreResult('')
    setSearchLoading(true)
    const requete = {
      user: {
          email: sessionStorage.getItem('currentMail')
      },
      search: {
          regiesupportlib: regiesupportlib,
          object: object,
          status: status,
          ordre: ordre,
          debut: debut,
          fin: fin,
          number: parseInt(top)
      },
    }
    console.log('Request Payload:', requete);
    setRequest(requete);

    GetDemandsAbs(requete)
      .then(reponse => {
        console.log('Response:', reponse);
        if (!reponse.ok) {
          const message = `An error has occurred: ${reponse.status}`;
          throw new Error(message);
        }
        return reponse.json();
      })
      .then(data => {
        var results = JSON.parse(data);
        console.log(results.results.demandeslist);
        console.log("longueur : ");
        console.log(results.results.demandeslist.length);
        setNombreResult(results.results.demandeslist.length);
        setSearchLoading(false)
        if (results.results.demandeslist.length > 0) {
          setIsLoading(false);
          setShowDashboard(false);
          setResult(results.results.demandeslist);
        } else {
          setIsLoading(false);
        }
        if (results.results.demandeslist.length > 0) {
          setShowFilter(false);
        }
      })
      .catch(error => {
        console.error('Request failed:', error);
        setIsLoading(false);
      });
  };


  const updateResulstList = () => {
    setLoadExportListe('')
    setIsLoading(true);
    setNombreResult('')
    GetDemandsAbs(request)
      .then(reponse => {
        console.log('Response:', reponse);
        if (!reponse.ok) {
          const message = `An error has occurred: ${reponse.status}`;
          throw new Error(message);
        }
        return reponse.json();
      })
      .then(data => {
        
        var results = JSON.parse(data);
        console.log(results.results.demandeslist);
        console.log("longueur : ");
        console.log(results.results.demandeslist.length);
        setNombreResult(results.results.demandeslist.length);
        if (results.results.demandeslist.length > 0) {
          setIsLoading(false);
          setShowDashboard(false);
          setResult(results.results.demandeslist);
        } else {
          setIsLoading(false);
          setResult(results.results);

        }
        if (results.results.demandeslist.length > 0) {
          setShowFilter(false);
        }
      })
      .catch(error => {
        console.error('Request failed:', error);
        setIsLoading(false);
      });
  };

  const openMailling = async (demandeId) => {
    const response = await GetOneDemandAbs(demandeId);
    if (!response.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }
    var data = await response.json();
    var results = JSON.parse(data);
    setOneDemand(results);
    setShowFilter(false);
    setShowMailing(true);
  };

  const closeMailling = () => {
    setShowMailing(false);
  };

  const openFilter = () => {
    setShowMailing(false);
    setShowFilter(true);
  }

  const closeFilter = () => {
    setShowFilter(false);
  }

  const handleFilterIcon = () => {

    if (showFilter == true){
      setShowFilter(false)
    }
    else{
      setShowFilter(true)
      setShowMailing(false);
    }
  }

  const handleFilterClose = () => {
    setShowFilter(false);
}


  const fileBuilt = (fichierEnBase64, mimetype) => {
    // Création du fichier
    let filecontent = atob(fichierEnBase64)
    var buffer = new ArrayBuffer(filecontent.length);
    var bufferView = new Uint8Array(buffer);
    
    for (var i = 0; i < filecontent.length; i++) {
      bufferView[i] = filecontent.charCodeAt(i);
    }
    const file = new Blob([bufferView], {type: mimetype}); 
  return file;
}

const exportListe = () => {

  if(nombreResult<1){
    return;
  }

  let request = {
      user: {
          email: sessionStorage.getItem("currentMail")
      },
      demandes: result
  };

  setLoadExportListe('loading');

  ExportExcel(request, "abs")
      .then(response => {
          if (!response.ok) {
              const message = `An error has occurred: ${response.status}`;
              throw new Error(message);
          }
          return response.json();
      })
      .then(data => {
          var results = JSON.parse(data);

          let fichierEnBase64 = results.results.fileslist[0].filecontent;
          let filename = results.results.fileslist[0].filename;
          let mimetype = results.results.fileslist[0].mimetype;
          
          const downloadFile = (fichierEnBase64, filename, mimetype) => {
              const file = fileBuilt(fichierEnBase64, mimetype);
              // Download du fichier
              const element = document.createElement('a');
              element.href = URL.createObjectURL(file);
              element.download = filename;
              document.body.appendChild(element);
              element.click();
              setLoadExportListe('loaded');
          };

          downloadFile(fichierEnBase64, filename, mimetype);
      })
      .catch(error => {
          console.error('Error:', error);
          setLoadExportListe('');
      });
};


  return (

    <div className={styles.root}>

      <div className={styles.pageTitle}>
        <h2>{nombreResult} {nombreResult > 1 && <span> Demandes</span>}{nombreResult < 2 && <span>Demande</span>} ABS 
        </h2>
      </div>

      <div className={styles.body}>


        <div className={styles.menuLeft}>
          {emailRole === 'media-servicing' ? <img className={styles.logoCarat} src={LogoCarat} alt="Demande Carat" onClick={demandeCaratOnClick} /> : null}
          <img src={Filter_svg} alt="Filter" onClick={handleFilterIcon} className={styles.filterImg}/> 
          
          <div>
            <ExportListeIcon loadState={loadExportListe} onclick={exportListe} numberResults={nombreResult} className={styles.exportListeIcon} />
          </div>
        </div>
        
        <div className={styles.container}>
          {/*emailRole === 'dentsu' || emailRole === 'media-servicing' ? <Button text="Demande Carat" onClick={demandeCaratOnClick} /> : null*/}

          <div className={styles.zindexSup}>

            <div className={styles.conversation}>
              {showMailing && <Mailing chatClose={closeMailling} oneDemand={oneDemand} updateData={() => openMailling(oneDemand.results.demandeslist[0].id)}/>}
            </div>
            
            {showFilter && <Filter style={{ zIndex: '100 !important' }} filterClose={closeFilter} filterChange={handleFilterChange} loadingState={searchLoading}/>}
          </div>

          {! showDashboard &&
          <div className={styles.demandes} >

            { nombreResult > 0 && result.map((demande, index) => (
              <Demande key={index} name={demande.regiesupportlib} number={demande.id} chatOpen={() => openMailling(demande.id)} date={demande.dateinit} status={demande.status} demandeur={demande.demandeur} objet={demande.objet} countEmail={demande.countemails} updateResulstList={updateResulstList}/>
            ))}
            {nombreResult == 0 && <p>Utilisez les filtres pour lancer ou modifier une recherche.</p>}


          </div>}


          {isLoading && <LoadingIcon />}
          {showDashboard && <Dashboard isLoading={handleLoading} />}
        </div>
      </div>


    </div>
  );
}

export default withAuth(SearchPage);
