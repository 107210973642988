import React, {useState} from "react";
import styles from "./Filter.module.css";
import cross_svg from "../../assets/cross.svg";
import Button from "../Button/Button";


function Filter({filterClose, filterChange}) {
    const [email, setEmail] = useState('');
    const [regiesupportlib, setRegiesupportlib] = useState('');
    const [object, setObject] = useState('');
    const [status, setStatus] = useState('');
    const [ordre, setOrdre] = useState('');
    const [debut, setDebut] = useState('');
    const [fin, setFin] = useState('');
    const [number, setNumber] = useState(0);
    
    const close = () => {
        filterClose();
    }

    const handleFilterChange = () => {
        console.log( "handle filter change() dans composant filter : email :"+email+", regiesupportlib: "+regiesupportlib+", object :"+object+", status:"+status+", ordre:"+ordre+", debut:"+debut+", fin:"+fin+", number:"+ number)
        filterChange(email, regiesupportlib, object, status, ordre, debut, fin, number);
    }

    return (
        <div className={styles.filter}>

            <div className={styles.encartBlanc}>

                <div className={styles.topFilter}>


                    <h1>Recherche ABS</h1>
                    <div className={styles.close} >
                        <img src={cross_svg} alt="close" onClick={close} />
                    </div>
                </div>
                
                <div className={styles.filterContainer}>
                    
                    <div className={styles.filterDiv}>
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" placeholder="Email du demandeur" onChange={(event) => setEmail(event.target.value)} />
                    </div>

                    <div className={styles.filterDiv}>
                        <label htmlFor="regiesupportlib">Fournisseur</label>
                        <input type="text" id="regiesupportlib" name="regiesupportlib" placeholder="Nom de la régie ou du support" onChange={(event) => setRegiesupportlib(event.target.value)} />
                    </div>

                    {/*<div className={styles.filterDiv}>
                        <label htmlFor="Agence">Agence</label>
                        <input type="text" id="Agence" name="Agence" />
                    </div>*/}

                    <div className={styles.filterDiv}>
                        <label htmlFor="objet">Objet</label>
                        {/* <input type="text" id="object" name="object" onChange={(event) => setObject(event.target.value)} /> */}
                        <select id="object" name="object" onChange={(event) => setObject(event.target.value)}>
                            <option value="">Sélectionner un type de demande</option>
                            <option value="Ajout d'un support à une régie existante">Ajout support régie existante</option>
                            <option value="Création de fournisseur">Création de régie</option>
                            <option value="Modification de coordonnées bancaires">Modification / Ajout de coordonnées bancaires</option>
                            <option value="Modification de lien support / régie">Modification lien support / régie</option>
                            <option value="Autre">Autre</option>
                        </select>
                    </div>

                    <div className={styles.filterDiv}>
                        <label htmlFor="status">Statut</label>
                        {/* <input type="text" id="status" name="status" onChange={(event) => setStatus(event.target.value)} /> */}
                        <select id="status" name="status" onChange={(event) => setStatus(event.target.value)}>
                            <option value=''>Tous les statuts</option>
                            <option value="Demande initiale">Demande initiale</option>
                            <option value="Demande en cours">Demande en cours</option>
                            <option value="Demande traitée">Demande traitée</option>
                        </select>
                    </div>
                    
                    <div className={styles.dates}>
                        <div className={styles.filterDiv}>
                            <label htmlFor="debut">Début</label>
                            <input type="date" id="debut" name="debut" onChange={(event) => setDebut(event.target.value)} />
                        </div>

                        <div className={styles.filterDiv}>
                            <label htmlFor="fin">Fin</label>
                            <input type="date" id="fin" name="fin" onChange={(event) => setFin(event.target.value)} />
                        </div>
                    </div>

                    <div className={styles.filterDiv}>
                        <label htmlFor="number">Nombre de résultats</label>
                        <input type="number" id="number" name="number" onChange={(event) => setNumber(event.target.value)} />
                    </div>
                    <div className={styles.filterDiv}>
                        <label htmlFor="ordre">Tri</label>
                        {/* <input type="text" id="ordre" name="ordre" onChange={(event) => setOrdre(event.target.value)} /> */}
                        <select id="ordre" name="ordre" onChange={(event) => setOrdre(event.target.value)}>
                            <option value=''>Sélectionner un tri</option>
                            <option value="Id Ascendant">Id Ascendant</option>
                            <option value="Id Descendant">Id Descendant</option>
                            <option value="Date Ascendant">Date Ascendant</option>
                            <option value="Date descendant">Date descendant</option>
                            <option value="Objet Ascendant">Objet Ascendant</option>
                            <option value="Objet Descendant">Objet Descendant</option>
                        </select>
                    </div>
                </div>
                <div className={styles.bouton}>
                    <Button text="Rechercher" onClick={handleFilterChange}/>
                </div>

            </div>


        </div>
    );
}

export default Filter;