import React, { useState, useRef } from "react";
import styles from "./OneDemandeCarat.module.css";
import Button from "../Button/Button";
import returnButton from "../../assets/bouton-retour.png"
import alertIcon from "../../assets/alert.png"
import {generateJsonFile, dateFormatDentsu, downloadFolder, formatDate} from "./GenerateJson.jsx";
import { Root, Request, Status, Vendor, Classification, Type, Group, Register, APE, Regimesocial, Address, Country, Document, DocType, FinancialData, ReglementCondition, ReglementMode, TVA, Type_TVA_Appliquee } from './../../models';
import { useEffect } from "react";
import { UpdateStatusInDentsuDb, saveDentsuJsonFile, GetFilesDentsu } from "../../Services/demandes.jsx";
import cross_svg from "../../assets/cross.svg";
import PopupAlert from "../PopUp/PopupAlert/PopupAlert.jsx";
import PopupConfirm from "../PopUp/PopupConfirm/PopupConfirm.jsx";
import JSZip from 'jszip';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate


function OneDemandeCarat(props) {

  const [role, setRole] = useState(sessionStorage.getItem('role'));
  const containerRef = useRef(null); 
  const navigate = useNavigate();
  const [messagePopup, setMessagePopup] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [idPopup, setIdPopup] = useState("")
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonDownloading, setButtonDownloading] = useState(false);

  const [RequestId, setRequestId] = useState(
    props.demande.Request[0].Id
  );
    const [RequestType, setRequestType] = useState(
    props.demande.Request[0].RequestType
  );
  const [RequestDate, setRequestDate] = useState(
    props.demande.Request[0].RequestDate
  );
  const [UserRequest, setUserRequest] = useState(
    props.demande.Request[0].UserRequest
  );
  const [RequestStatus, setRequestStatus] = useState(
    props.demande.Request[0].RequestStatus.Name
  );
  const [RequestStatusId, setRequestStatusId] = useState(0);
  const [VendorId, setVendorId] = useState(props.demande.Request[0].Vendor.Id);
  const [VendorCommercialName, setVendorCommercialName] = useState(
    props.demande.Request[0].Vendor.CommercialName
  );
  const [VendorExternalId, setVendorExternalId] = useState(
    props.demande.Request[0].Vendor.ExternalId
  );
  const [VendorGroupName, setVendorGroupName] = useState(
    props.demande.Request[0].Vendor.GroupName
  );
  const [VendorFreelance, setVendorFreelance] = useState(
    props.demande.Request[0].Vendor.Freelance
  );
  const [VendorActivityDescription, setVendorActivityDescription] = useState(
    props.demande.Request[0].Vendor.ActivityDescription
  );
  const [VendorAddressesType, setVendorAddressesType] = useState(
    props.demande.Request[0].Vendor.Addresses[0].Type.Id
  );
  const [VendorAddressesTypeName, setVendorAddressesTypeName] = useState(
    props.demande.Request[0].Vendor.Addresses[0].Type.Name
  );
  const [VendorTypeId, setVendorTypeId] = useState(
    props.demande.Request[0].Vendor.Type.Id
  );
  const [VendorTypeName, setVendorTypeName] = useState(
    props.demande.Request[0].Vendor.Type.Name
  );
  const [VendorClassificationId, setVendorClassificationId] = useState(
    props.demande.Request[0].Vendor.Classification.Id
  );
  const [VendorClassificationName, setVendorClassificationName] = useState(
    props.demande.Request[0].Vendor.Classification.Name
  );
  const [VendorGroupId, setVendorGroupId] = useState(
    props.demande.Request[0].Vendor.Group.Id
  );
  const [VendorGroupName1, setVendorGroupName1] = useState(
    props.demande.Request[0].Vendor.Group.Name
  );
  const [VendorRegisterCountryIdiso3, setVendorRegisterCountryIdiso3] =
    useState(props.demande.Request[0].Vendor.Register.Country.Idiso3);
  const [VendorRegisterCountryName, setVendorRegisterCountryName] = useState(
    props.demande.Request[0].Vendor.Register.Country.Name
  );
  const [VendorRegisterCountryId, setVendorRegisterCountryId] = useState(
    props.demande.Request[0].Vendor.Register.Country.Id
  );
  const [VendorRegisterAPEName, setVendorRegisterAPEName] = useState(
    props.demande.Request[0].Vendor.Register.APE.Name
  );
  const [VendorRegisterAPECode, setVendorRegisterAPECode] = useState(
    props.demande.Request[0].Vendor.Register.APE.Code
  );
  const [VendorRegisterRegimesocialName, setVendorRegisterRegimesocialName] =
    useState(props.demande.Request[0].Vendor.Register.Regimesocial.Name);
  const [VendorRegisterRegimesocialId, setVendorRegisterRegimesocialId] =
    useState(props.demande.Request[0].Vendor.Register.Regimesocial.Id);
  const [VendorRegisterSerialNumber, setVendorRegisterSerialNumber] = useState(
    props.demande.Request[0].Vendor.Register.SerialNumber
  );
  const [VendorRegisterLegalName, setVendorRegisterLegalName] = useState(
    props.demande.Request[0].Vendor.Register.LegalName
  );
  const [VendorRegisterLegalStatus, setVendorRegisterLegalStatus] = useState(
    props.demande.Request[0].Vendor.Register.LegalStatus
  );
  const [VendorRegisterVATNumber, setVendorRegisterVATNumber] = useState(
    props.demande.Request[0].Vendor.Register.VATNumber
  );
  const [VendorRegisterRCS, setVendorRegisterRCS] = useState(
    props.demande.Request[0].Vendor.Register.RCS
  );
  const [VendorRegisterCapital, setVendorRegisterCapital] = useState(
    props.demande.Request[0].Vendor.Register.Capital
  );
  const [
    VendorRegisterNumeroSecuriteSocial,
    setVendorRegisterNumeroSecuriteSocial,
  ] = useState(props.demande.Request[0].Vendor.Register.NumeroSecuriteSocial);
  const [addresses, setAddresses] = useState(
    props.demande.Request[0].Vendor.Addresses
  );
  const [VendorAddressesDefault, setVendorAddressesDefault] = useState(
    props.demande.Request[0].Vendor.Addresses[0].Default
  );
  const [VendorAddressesCompany, setVendorAddressesCompany] = useState(
    props.demande.Request[0].Vendor.Addresses[0].Company
  );
  const [VendorAddressesExternalId, setVendorAddressesExternalId] = useState(
    props.demande.Request[0].Vendor.Addresses[0].ExternalId
  );
  const [VendorAddressesId, setVendorAddressesId] = useState(
    props.demande.Request[0].Vendor.Addresses[0].Id
  );
  const [VendorAddressesAdresse1, setVendorAddressesAdresse1] = useState(
    props.demande.Request[0].Vendor.Addresses[0].Adresse1
  );
  const [VendorAddressesAdresse2, setVendorAddressesAdresse2] = useState(
    props.demande.Request[0].Vendor.Addresses[0].Adresse2
  );
  const [VendorAddressesZipCode, setVendorAddressesZipCode] = useState(
    props.demande.Request[0].Vendor.Addresses[0].ZipCode
  );
  const [VendorAddressesCity, setVendorAddressesCity] = useState(
    props.demande.Request[0].Vendor.Addresses[0].City
  );
  const [VendorAddressesCountryId, setVendorAddressesCountryId] = useState(
    props.demande.Request[0].Vendor.Addresses[0].Country.Id
  );
  const [VendorAddressesCountryName, setVendorAddressesCountryName] = useState(
    props.demande.Request[0].Vendor.Addresses[0].Country.Name
  );
  const [documents, setDocuments] = useState(
    props.demande.Request[0].Vendor.Documents
  );
  const [VendorDocumentsDocTypeId, setVendorDocumentsDocTypeId] = useState(
    props.demande.Request[0].Vendor.Documents[0].DocType.Id
  );
  const [VendorDocumentsDocTypeName, setVendorDocumentsDocTypeName] = useState(
    props.demande.Request[0].Vendor.Documents[0].DocType.Name
  );
  const [
    VendorDocumentsDocTypeFlMandatory,
    setVendorDocumentsDocTypeFlMandatory,
  ] = useState(
    props.demande.Request[0].Vendor.Documents[0].DocType.FlMandatory
  );
  const [VendorDocumentsFileName, setVendorDocumentsFileName] = useState(
    props.demande.Request[0].Vendor.Documents[0].FileName
  );
  const [VendorDocumentsFileType, setVendorDocumentsFileType] = useState(
    props.demande.Request[0].Vendor.Documents[0].FileType
  );
  const [
    VendorFinancialDataReglementConditionName,
    setVendorFinancialDataReglementConditionName,
  ] = useState(
    props.demande.Request[0].Vendor.FinancialData.ReglementCondition.Name
  );
  const [VendorFinancialDataReglementConditionId, setVendorFinancialDataReglementConditionId] = useState(
    props.demande.Request[0].Vendor.FinancialData.ReglementCondition.Id
  );

  const [
    VendorFinancialDataReglementModeName,
    setVendorFinancialDataReglementModeName,
  ] = useState(
    props.demande.Request[0].Vendor.FinancialData.ReglementMode.Name
  );
  const [VendorFinancialDataReglementModeId, setVendorFinancialDataReglementModeId] = useState(
    props.demande.Request[0].Vendor.FinancialData.ReglementMode.Id
  );
  const [
    VendorFinancialDataType_TVA_AppliqueeName,
    setVendorFinancialDataType_TVA_AppliqueeName,
  ] = useState(
    props.demande.Request[0].Vendor.FinancialData.Type_TVA_Appliquee.Name
  );
  const [VendorFinancialDataType_TVA_AppliqueeId, setVendorFinancialDataType_TVA_AppliqueeId] = useState(
    props.demande.Request[0].Vendor.FinancialData.Type_TVA_Appliquee.Id
  );
  const [statusIsSubmit, setStatusIsSubmit] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [reason, setReason] = useState(props.demande.Request[0].RequestStatus.Raison);
  const [reasonRequired, setReasonRequired] = useState(false);
  const [isRadioChecked, setIsRadioChecked] = useState(false);
  const [ErrorStatus, setErrorStatus] = useState('');

  const handleBack = () => {
    props.backToDemande();
  };

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleClosePopup =() =>{
    setIdPopup("")
    setShowPopup(false)
    handleBack();

  }

  const handleChangeAdresse = (index, field, value) => {
    const newAddresses = [...addresses];

    if (field === "Country.Id") {
      newAddresses[index] = {
        ...newAddresses[index],
        Country: { ...newAddresses[index].Country, Id: value }
      };
    } else if (field === "Type.Id") {
      newAddresses[index] = {
        ...newAddresses[index],
        Type : { ...newAddresses[index].Type, Id: value }
      };
    } else {
      newAddresses[index] = { ...newAddresses[index], [field]: value };
    }
    
    setAddresses(newAddresses);
  };

 const generateJsonFile = async (requestObj, id, status) => {
      setButtonLoading(true)
      const requestsArray = [requestObj];
      let rootObj = new Root(requestsArray);
    
    //SERIALISER    
    let jsonString = JSON.stringify(rootObj);
    
    // TO BASE 64 
    let jsonToBase64 = btoa(unescape(encodeURIComponent(jsonString)))
    
    //DATE POUR NOM
    let now = new Date();
    let date = String(now.getDate()).padStart(2, '0') + String(now.getMonth() + 1).padStart(2, '0') + String(now.getFullYear()).padStart(4, '0'); 
    let nomJsonFile = "Dentsu_" + requestObj.Id + "_" + date;
    
    const request = {
        user:{
            email: sessionStorage.getItem("currentMail")
        },
        detail: {
            id:props.detailId,
            demandeid:id,
            status:status,
            jsonfile:jsonToBase64,
            jsonfilename:nomJsonFile
        }
    }
    const reponse = await saveDentsuJsonFile(request);

    if (!reponse.ok) {
        setButtonLoading(false)
        const message = `An error has occurred: ${reponse.status}`;
        throw new Error(message);
    }else{
      props.updateResultsList()
      .then(() => {
          setButtonLoading(false)
          setMessagePopup("Modifications enregistrées")
          setIdPopup("generateJson")
          setShowPopup(true)          
      })
      .catch(error => {
          setButtonLoading(false)
          console.error("Erreur lors de la mise à jour des résultats :", error);
          setMessagePopup("Erreur : " + error)
          setIdPopup("generateJson")
          setShowPopup(true)
      });
    }   
  }

  const updateStatusBackToSubmit=()=>{
    setMessagePopup("Vous êtes sur le point de passer cette demande en Submit. Souhaitez-vous continuer ?")
    setIdPopup("confirmSubmitAgain")

  }

  const cancelStatusUpdate = () => {

    setMessagePopup("")
    setIdPopup("")
    setShowPopup(false)
  }

  const updateStatusInDb = async () => {
    setShowPopup(false)
    setIdPopup("")
    setMessagePopup("")
    
        const request = {
          user:{
              email: sessionStorage.getItem("currentMail")
          },
          detail: {
            id:props.detailId,
            demandeid:RequestId
          }
        }
    
        const reponse = await UpdateStatusInDentsuDb(request);
        if (!reponse.ok) {
            const message = `An error has occurred: ${reponse.status}`;
            setMessagePopup("Erreur lors de la tentative de modification du statut.")
            setShowPopup(true)
            throw new Error(message);
        } else {
          props.updateResultsList()
          .then(() => {
            setMessagePopup("Nouveau statut : Submit")
            setShowPopup(true)           
        })
        .catch(error => {
            console.error("Erreur lors de la mise à jour des résultats :", error);
            setMessagePopup("Une erreur s'est produite lors du rechargement des résultats. Pensez à relancer la recherche.")
            setShowPopup(true)
        });
        }  
    
    }

  function handleButtonValider() {

    if (selectedStatus === '') {
      setErrorStatus('Veuillez sélectionner un statut.');
      setTimeout(() => {
          if (containerRef.current) {
              containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
          }
      }, 0);
      return;
  }

  if (reasonRequired && reason === '') {
      setTimeout(() => {
          if (containerRef.current) {
              containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
          }
      }, 0);
      return;
  }

    let newDateNow = dateFormatDentsu() // DateTime.UtcNow.ToString("yyyy-MM-ddTHH:mm:ss.fff");
    let statusObj = new Status({dateUpdate:newDateNow, Name:selectedStatus, Id:RequestStatusId, Raison:reason})
    let groupeObj = new Group({Id:VendorGroupId, ExternalId:0, Name:""})
    let apeObj = new APE({ Code:VendorRegisterAPECode , Name:""  })
    let regimeSoObj = new Regimesocial({Id:VendorRegisterRegimesocialId, Name:""})
    let countryObj = new Country({Id:VendorRegisterCountryId, Name:"", Idiso3:""})
    let typeObj = new Type({ Id: VendorTypeId , Name: VendorTypeName })
    let classifObj = new Classification({ Id: VendorClassificationId, Name: VendorClassificationName })
    let rgltCondObj = new ReglementCondition({ Id:VendorFinancialDataReglementConditionId , Name: "" })
    let rgltModeObj = new ReglementMode({ Id: VendorFinancialDataReglementModeId, Name: "" })
    let tvaObj = new Type_TVA_Appliquee({ Id: VendorFinancialDataType_TVA_AppliqueeId, Name: "" })
    let financialObj = new FinancialData({ ReglementCondition : rgltCondObj, ReglementMode: rgltModeObj, Type_TVA_Appliquee:tvaObj})
    let registerObj = new Register({
      Country:countryObj, 
      APE: apeObj, 
      Regimesocial: regimeSoObj, 
      SerialNumber:VendorRegisterSerialNumber, 
      LegalName:VendorRegisterLegalName, 
      VATNumber:VendorRegisterVATNumber, 
      LegalStatus:VendorRegisterLegalStatus, 
      RCS:VendorRegisterRCS, 
      Capital:VendorRegisterCapital, 
      NumeroSecuriteSocial:VendorRegisterNumeroSecuriteSocial
    })
    let vendorObj =  new Vendor({
      Id:VendorId, 
      ExternalId:VendorExternalId, 
      CommercialName:VendorCommercialName, 
      Type:typeObj, 
      Classification: classifObj, 
      Freelance:VendorFreelance, 
      GroupName:VendorGroupName, 
      Group:groupeObj, 
      ActivityDescription: VendorActivityDescription, 
      Register: registerObj, 
      Addresses: addresses, 
      Documents:documents, 
      FinancialData:financialObj 
    })

    let requestObj = new Request({
      Id:RequestId, 
      RequestType:RequestType, 
      RequestDate:RequestDate, 
      UserRequest:UserRequest, 
      RequestStatus : statusObj, 
      Vendor:vendorObj
    })


    // appeler generate json file avec les objets prets
    generateJsonFile(requestObj, RequestId, selectedStatus)

  }



  let tooltipElem;
  function infoBulle(event){
    let target = event.target;
    
      // if we have tooltip HTML...
      let tooltipHtml = target.dataset.tooltip;
      if (!tooltipHtml) return;
      // ...create the tooltip element

      tooltipElem = document.createElement('div');
      tooltipElem.className = styles.tooltip;
      tooltipElem.innerHTML = tooltipHtml;
      document.body.append(tooltipElem);
  
      // position it above the annotated element (top-center)
      let coords = target.getBoundingClientRect();
      let left = coords.left + (target.offsetWidth - tooltipElem.offsetWidth) / 2;

      if (left < 0) left = 0; // don't cross the left window edge
      let top = coords.top - tooltipElem.offsetHeight - 5;
      if (top < 0) { // if crossing the top window edge, show below instead
        top = coords.top + target.offsetHeight + 5;
      }

      tooltipElem.style.left = left + 'px';
      tooltipElem.style.top = top + 'px';
    }; 
    document.onmouseout = function(e) {

      hideToolTip()

    };
    

    function downloadFolder(demandeid) {
      setButtonDownloading(true);
    
      return GetFilesDentsu(demandeid)
        .then((response)=>{
          if (!response.ok) {
            setButtonDownloading(false);
            const message = `An error has occurred: ${response.status}`;
            throw new Error(message);
          }
          return response.json();
        })
        .then((data) => {
          const result = JSON.parse(data);

          if(result.status.code===204){
            setMessagePopup("Erreur lors de la récupération des fichiers.")
            setShowPopup(true)
          }
          

          let files = result.results.fileslist;
          let zip = new JSZip();
    
          // Ajouter chaque fichier au ZIP
          files.forEach(function(fichier) {
            let fich = fichier.filecontent;
    
            if (fichier.filecontent.includes(";base64,")) {
              let arrayBase64 = fichier.filecontent.split(";base64,");
              fich = arrayBase64[1];
            }
    
            zip.file(fichier.filename, fich, { base64: true });
          });
    
          // Générer le contenu du ZIP
          return zip.generateAsync({ type: "blob" });
        })
        .then((blob)=>{
          // Créer un objet URL pour le blob
          let url = URL.createObjectURL(blob);
    
          // Créer un lien pour télécharger le ZIP
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Demande'+ demandeid +'.zip');
    
          // Ajouter le lien à la page et cliquer dessus pour déclencher le téléchargement
          document.body.appendChild(link);
          link.click();
    
          setButtonDownloading(false);
        })
        .catch(function(error) {
          console.error('Error downloading folder:', error);
          setButtonDownloading(false);
        });
    }
    

function hideToolTip() {
  if (tooltipElem) {
    tooltipElem.remove();
    tooltipElem = null;
  }
}


  useEffect(() => {
    if (props.statutDemande === "Submit") {
      setStatusIsSubmit(true);
    } else {
      setStatusIsSubmit(false);
    }
  }, [props.demande.Request]);

const [deux, setDeux] = useState(props.doublon)

  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus(status);
    setErrorStatus('')
    setIsRadioChecked(true);

    if(status === 'Return For Update'){
      setRequestStatusId(1)
    }
    else if(status === 'Accept'){
      setRequestStatusId(2)
    }
    else if(status === 'Cancel'){
      setRequestStatusId(6)
    }

    if (status === 'Return For Update' || status === 'Cancel') {
      setReasonRequired(true);
    } else {
      setReasonRequired(false);
      setReason('');
    }
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };


  return (
    <div className={styles.container} ref={containerRef}>

      <div className={styles.titleContainer}>
        <img src={returnButton} onClick={handleBack} ></img>

        <div className={styles.titlePart}>
          <h2>Demande #{props.demande.Request[0].Id} : {props.demande.Request[0].Vendor.Register.LegalName}</h2> 
          
          <div className={styles.subtitle}>
            <div className={styles.left}>{props.demande.Request[0].RequestType}</div>
            
            Envoyée le {formatDate(RequestDate)} par {UserRequest}
            
          </div>
        </div>

        <div className={styles.status}>

          <div className={styles.statusFlag}>{props.statutDemande}</div>

          { role==='media-servicing' && props.statutDemande === "Waiting Completed" && 
          (<p onClick={()=>updateStatusBackToSubmit()}>
              <span>Changer en Submit</span>
          </p>)
          }
          {  role==='media-servicing' && props.statutDemande === "Sended" && 
          (<p onClick={()=>updateStatusBackToSubmit()}>
              <span>Changer en Submit</span>
          </p>)
          }
          {  role==='media-servicing' && props.statutDemande === "Return For Update" && 
          (<p onClick={()=>updateStatusBackToSubmit()}>
              <span>Changer en Submit</span>
          </p>)
          }
          {  role==='media-servicing' && props.statutDemande === "Accept" && 
          (<p onClick={()=>updateStatusBackToSubmit()}>
              <span>Changer en Submit</span>
          </p>)
          }
        </div>
      </div>
      
      <div className={styles.information}>
        <div className={styles.topInfo}>

          <div className={styles.statut}>

            {reason.trim() !== '' && 
              <div>
                <label>Raison : </label>
                <p>{reason}</p>
              </div>
            }

            {statusIsSubmit && role === 'media-servicing' && (
              <div>
                <h3>Statut '{props.demande.Request[0].RequestStatus.Name}'. Passer à :</h3>
                <div className={styles.newStatus}>

                  <div className={styles.labelsPart}>
                    <label>
                      <input
                        type="radio"
                        name="status"
                        value="Accept"
                        onChange={handleStatusChange}
                      />
                      Accept 
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="status"
                        value="Return For Update"
                        onChange={handleStatusChange}
                      />
                      Return for update
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="status"
                        value="Cancel"
                        onChange={handleStatusChange}
                      />
                      Cancel
                    </label>
                  </div>

                  <div className={styles.statusOtherData}>
                  {reasonRequired && (

                    <div>
                      <textarea
                      className={styles.raison}
                        placeholder="Raison"
                        value={reason}
                        onChange={handleReasonChange}
                        required={reasonRequired}
                      />
                      {reason === '' && (
                        <p style={{ color: 'yellow' }}>La raison est obligatoire pour ce statut.</p>
                      )}
                    </div>
                  )}
                  {ErrorStatus && (
                    <p style={{ color: 'yellow' }}>{ErrorStatus}</p>
                  )}
                  </div>
                </div>


              </div>           


            )}
          </div>
        </div>
        

        <div className={styles.vendorInfo}>

          <div className={styles.vendorInfo1}>

            
            <h3>Vendor</h3>

            <div className={styles.vendorRow}>

              {/*Nom commercial*/}
              <div className={styles.modifiable}>
                <div className={styles.champsVendor}>
                  <p onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.CommercialName.maxChar} caractères max`}>{props.champs.Vendor.CommercialName.nameOnScreen}*</p>
                  <input 
                    type="text" 
                    defaultValue={props.demande.Request[0].Vendor.CommercialName} 
                    disabled={!props.champs.Vendor.CommercialName.modifiable} 
                    onChange={handleChange(setVendorCommercialName)} 
                    maxLength={props.champs.Vendor.CommercialName.maxChar}/>
                </div>
              </div>


              {/*Vendor Id*/}
              <div className={styles.champsVendor}>
                <p>{props.champs.Vendor.Id.nameOnScreen}</p>
                <input type="text" defaultValue={props.demande.Request[0].Vendor.Id} disabled={!props.champs.Vendor.ExternalId.modifiable} onChange={handleChange(setVendorId)} />
              </div>

              {/*External Id*/}
              <div className={styles.champsVendor}>
                <p>{props.champs.Vendor.ExternalId.nameOnScreen}</p>
                <input type="text" defaultValue={props.demande.Request[0].Vendor.ExternalId} disabled={!props.champs.Vendor.ExternalId.modifiable} onChange={handleChange(setVendorExternalId)} />
              </div>

            </div>

            <div className={styles.vendorRow}>

              {/*Nom de groupe*/}
              <div className={styles.champsVendor}>
                <p>{props.champs.Vendor.GroupName.nameOnScreen}</p>
                <input type="text" defaultValue={props.demande.Request[0].Vendor.GroupName} disabled={!props.champs.Vendor.GroupName.modifiable} onChange={handleChange(setVendorGroupName)}/>
              </div>


              {/*Freelance*/}
              <div className={styles.champsVendor}>
                <p>{props.champs.Vendor.Freelance.nameOnScreen}</p>
                <input type="text" defaultValue={props.demande.Request[0].Vendor.Freelance} disabled={!props.champs.Vendor.Freelance.modifiable} onChange={handleChange(setVendorFreelance)}/>
              </div>

              {/*Activity*/}
              <div className={styles.modifiable}>
                <div  className={styles.champsVendor}>
                  <p onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.ActivityDescription.maxChar} caractères max`}>{props.champs.Vendor.ActivityDescription.nameOnScreen}*</p>
                  <input type="text" 
                    defaultValue={props.demande.Request[0].Vendor.ActivityDescription} 
                    disabled={!props.champs.Vendor.ActivityDescription.modifiable} 
                    onChange={handleChange(setVendorActivityDescription)}
                    maxLength={props.champs.Vendor.ActivityDescription.maxChar}/>
                </div>
              </div>
            </div>
            
            <div className={styles.vendorRow}>

              {/*Group*/}
              <div className={styles.modifiable}>
                <div className={styles.champsVendor}>
                  <p>{props.champs.Vendor.Group.Name.nameOnScreen}</p>
                  {role === 'media-servicing' && (
                      <select
                      name="GroupName"
                      id="GroupName"
                      disabled={!props.champs.Vendor.Group.Name.modifiable}
                      onChange={handleChange(setVendorGroupId)}
                      defaultValue={props.demande.Request[0].Vendor.Group.Id}
                    >
                      {/* Option par défaut */}
                      <option value="" disabled hidden>
                        Sélectionner
                      </option>

                      {/* Options dynamiques */}
                      {props.combos.groupeRegie
                        .sort((a, b) => {
                          if (a.name === props.demande.Request[0].Vendor.Group.Name) {
                            return -1;
                          }
                          if (b.name === props.demande.Request[0].Vendor.Group.Name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      <option value="0">Non renseigné</option>
                    </select>
                  )}
                  {role === 'dentsu' && (<input type="text" value={props.demande.Request[0].Vendor.Group.Name}/>)}

                </div>
              </div>

              {/*Type*/}
              <div  className={styles.champsVendor}>
                <p>{props.champs.Vendor.Type.Name.nameOnScreen}</p>
                <input
                  type="text"
                  defaultValue={
                    props.demande.Request[0].Vendor.Type.Name
                  }
                  disabled={
                    !props.champs.Vendor.Type.Name.modifiable
                  }
                  onChange={handleChange(setVendorTypeName)}
                />
              </div>

              {/*Classif*/}
              <div className={styles.champsVendor}>
                <p>{props.champs.Vendor.Classification.Name.nameOnScreen}</p>
                <input
                  type="text"
                  defaultValue={
                    props.demande.Request[0].Vendor.Classification.Name
                  }
                  disabled={!props.champs.Vendor.Classification.Name.modifiable}
                  onChange={handleChange(setVendorClassificationName)}
                />
              </div>

            </div>
          </div>
          <div className={styles.vendorInfo2}>
            <h3>Register</h3>
            <div className={styles.all}>

            {/*DEUXIEME LIGNE : 3 items*/}
            <div className={styles.secondRow}>

                {/*Country*/}
                <div className={styles.modifiable}>
                  <div >
                    <p>{props.champs.Vendor.Register.Country.Name.nameOnScreen}</p>
                    <select
                      className={styles.selectInRegister}
                      name="CountryName"
                      id="CountryName"
                      disabled={!props.champs.Vendor.Register.Country.Name.modifiable}
                      onChange={handleChange(setVendorRegisterCountryId)}
                      defaultValue={props.demande.Request[0].Vendor.Register.Country.Id}
                    >
                      {/* Option par défaut */}
                      <option value="" disabled hidden>
                        Sélectionner
                      </option>

                      {/* Options dynamiques */}
                      {props.combos.country
                        .sort((a, b) => {
                          if (a.name === props.demande.Request[0].Vendor.Register.Country.Name) {
                            return -1;
                          }
                          if (b.name === props.demande.Request[0].Vendor.Register.Country.Name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                        <option value="0">Non renseigné</option>
                    </select>

                  </div>
                </div>

                {/*APE*/}
                <div  className={styles.modifiable}>
                  <div>
                    <p>{props.champs.Vendor.Register.APE.Name.nameOnScreen}</p>
                    <select
                      className={styles.selectInRegister}
                      name="APEName"
                      id="APEName"
                      disabled={!props.champs.Vendor.Register.APE.Name.modifiable}
                      onChange={handleChange(setVendorRegisterAPECode)}
                      defaultValue={props.demande.Request[0].Vendor.Register.APE.Code}
                    >
                      {/* Option par défaut */}
                      <option value="" disabled hidden>
                        Sélectionner
                      </option>

                      {/* Options dynamiques */}
                      {props.combos.codeApeNaf.map((ape) => (
                        <option key={ape.code} value={ape.code}>
                          {ape.name}
                        </option>
                      ))}
                      <option value="">Non renseigné</option>
                    </select>
                  </div>                  
                </div>

                {/*Regime social*/}
                <div  className={styles.modifiable}>
                  <div>
                    <p>{props.champs.Vendor.Register.Regimesocial.Name.nameOnScreen}</p>
                    <select
                      className={styles.selectInRegister}
                      name="RegimeSocialName"
                      id="RegimeSocialName"
                      disabled={!props.champs.Vendor.Register.Regimesocial.Name.modifiable}
                      onChange={handleChange(setVendorRegisterRegimesocialId)}
                      defaultValue={props.demande.Request[0].Vendor.Register.Regimesocial.Id}
                    >
                      {/* Option par défaut */}
                      <option value="" disabled hidden>
                        Sélectionner
                      </option>

                      {/* Options dynamiques */}
                      {props.combos.secuSo
                        .sort((a, b) => {
                          if (a.name === props.demande.Request[0].Vendor.Register.Regimesocial.Name) {
                            return -1;
                          }
                          if (b.name === props.demande.Request[0].Vendor.Register.Regimesocial.Name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((regimeSocial) => (
                          <option key={regimeSocial.id} value={regimeSocial.id}>
                            {regimeSocial.name}
                          </option>
                        ))}
                        <option value="0">Non renseigné</option>
                    </select>

                  </div>
                </div>

              {/*RCS*/}
              <div  className={styles.modifiable}>
                <p onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.Register.RCS.maxChar} caractères max`}>{props.champs.Vendor.Register.RCS.nameOnScreen}*</p>
                <input
                  type="text"
                  defaultValue={props.demande.Request[0].Vendor.Register.RCS}
                  disabled={!props.champs.Vendor.Register.RCS.modifiable}
                  onChange={handleChange(setVendorRegisterRCS)}
                  maxLength={props.champs.Vendor.Register.RCS.maxChar}
                />
              </div>

              {/*Capital*/}
              <div  className={styles.modifiable}>
                <p>{props.champs.Vendor.Register.Capital.nameOnScreen}</p>
                <input
                  type="text"
                  defaultValue={props.demande.Request[0].Vendor.Register.Capital}
                  disabled={!props.champs.Vendor.Register.Capital.modifiable}
                  onChange={handleChange(setVendorRegisterCapital)}
                />
              </div>
            </div>


              {/*LIGNE 1 :  4 items*/}
              <div className={styles.firstRow}>

                {/*Serial Number*/}
                <div>
                  <div className={styles.serialNumber}>
                    <p>{props.champs.Vendor.Register.SerialNumber.nameOnScreen}</p>
                    {deux === 'true' &&  <img onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip="Risque de doublon !" src={alertIcon} className={styles.alertIcon} /> }
                  </div>
                  <input type="text"
                    defaultValue={props.demande.Request[0].Vendor.Register.SerialNumber}
                    disabled={!props.champs.Vendor.Register.SerialNumber.modifiable}
                    onChange={handleChange(setVendorRegisterSerialNumber)}
                  />
                </div>

                {/*Legal Name*/}
                <div  className={styles.modifiable}>
                  <p onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.Register.LegalName.maxChar} caractères max`}>{props.champs.Vendor.Register.LegalName.nameOnScreen}* </p>
                  <input
                    type="text"
                    defaultValue={
                      props.demande.Request[0].Vendor.Register.LegalName
                    }
                    disabled={
                      !props.champs.Vendor.Register.LegalName.modifiable
                    }
                    onChange={handleChange(setVendorRegisterLegalName)}
                  />
                </div>

                {/*Legal Status*/}
                <div  className={styles.modifiable}>
                  <p  onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.Register.LegalStatus.maxChar} caractères max`}>{props.champs.Vendor.Register.LegalStatus.nameOnScreen}*</p>
                  <input type="text"
                    defaultValue={props.demande.Request[0].Vendor.Register.LegalStatus}
                    disabled={!props.champs.Vendor.Register.LegalStatus.modifiable}
                    onChange={handleChange(setVendorRegisterLegalStatus)}
                    maxLength={props.champs.Vendor.Register.LegalStatus.maxChar}
                  />
                </div>

                {/*VAT Number*/}
                <div  className={styles.modifiable}>
                  <p  onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.Register.VATNumber.maxChar} caractères max`}>{props.champs.Vendor.Register.VATNumber.nameOnScreen}*</p>
                  <input type="text"
                    defaultValue={props.demande.Request[0].Vendor.Register.VATNumber}
                    disabled={!props.champs.Vendor.Register.VATNumber.modifiable}
                    onChange={handleChange(setVendorRegisterVATNumber)}
                    maxLength={props.champs.Vendor.Register.VATNumber.maxChar}
                  />
                </div>

                {/*Numero Secu social*/}
                <div  className={styles.modifiable}>
                  <p  onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.Register.NumeroSecuriteSocial.maxChar} caractères max`}>{props.champs.Vendor.Register.NumeroSecuriteSocial.nameOnScreen}*</p>
                  <input type="text"
                    defaultValue={props.demande.Request[0].Vendor.Register.NumeroSecuriteSocial}
                    disabled={ !props.champs.Vendor.Register.NumeroSecuriteSocial.modifiable}
                    onChange={handleChange(setVendorRegisterNumeroSecuriteSocial)}
                    maxLength={props.champs.Vendor.Register.NumeroSecuriteSocial.maxChar}/>
                </div>

              </div>


            </div>

          </div>

          <div className={styles.vendorInfo2}>
            <h3>Adresses</h3>
            <div>
              {props.demande.Request[0].Vendor.Addresses.map(
                (address, index) => (

                  <div className={styles.section1adresse} key={index}>


                    <h3>
                      #{address.Type.Id}: {address.Type.Name}{" "}
                    </h3>


                    <div className={styles.all}>

                      {/*Ligne*/}
                      <div className={styles.firstRow}>

                        {/*COMBO Default*/}
                        <div  className={styles.modifiable}>
                          <p>{props.champs.Vendor.Addresses[0].Default.nameOnScreen}</p>
                          <select
                            className={styles.selectInAdresses}
                            name="Default"
                            id="Default"
                            disabled={!props.champs.Vendor.Addresses[0].Default.modifiable}
                            onChange={(e) => handleChangeAdresse(index, "Default", e.target.value)}
                            defaultValue={address.Default}
                          >
                            {/* Option par défaut */}
                            <option value="" disabled hidden>
                              Sélectionner
                            </option>
                            <option value="Yes">oui</option>
                            <option value="No">non</option>
                          </select>
                        </div>
                        
                        {/*Id*/}
                        <div>
                          <p>{props.champs.Vendor.Addresses[0].Id.nameOnScreen}</p>
                          <input
                            type="text"
                            defaultValue={address.Id}
                            disabled={
                              !props.champs.Vendor.Addresses[0].Id
                                .modifiable
                            }
                            onChange={(e) =>
                              handleChangeAdresse(
                                index,
                                "Id",
                                e.target.value
                              )
                            }
                          />
                        </div>

                        {/* Adresse External Id*/}
                        <div className={styles.modifiable}>
                          <p>{props.champs.Vendor.Addresses[0].ExternalId.nameOnScreen}</p>
                          <input
                            type="text"
                            defaultValue={address.ExternalId}
                            disabled={
                              !props.champs.Vendor.Addresses[0].ExternalId
                                .modifiable
                            }
                            onChange={(e) =>
                              handleChangeAdresse(
                                index,
                                "ExternalId",
                                e.target.value
                              )
                            }
                          />
                        </div>

                        {/*Type Adresse*/}
                        <div  className={styles.modifiable}>
                          <p>{props.champs.Vendor.Addresses[0].Type.Name.nameOnScreen}</p>
                          <select
                            className={styles.selectInAdresses}
                            name="Type"
                            id="Type"
                            disabled={!props.champs.Vendor.Addresses[0].Type.Name.modifiable}
                            onChange={(e) => handleChangeAdresse(index, "Type.Id", e.target.value)}
                            defaultValue={address.Type.Id}
                          >
                            {/* Option par défaut */}
                            <option value="" disabled hidden>
                              Sélectionner
                            </option>
                            {/* Options dynamiques */}
                            {props.combos.addressType
                              .sort((a, b) => {
                                if (a.name === address.Type.Name) {
                                  return -1;
                                }
                                if (b.name === address.Type.Name) {
                                  return 1;
                                }
                                return 0;
                              })
                              .map((type) => (
                                <option key={type.id} value={type.id}>
                                  {type.name}
                                </option>
                              ))}
                              <option value="0">Non renseigné</option>
                          </select>

                        </div>

                        {/*Country Name*/}
                        <div  className={styles.modifiable}>
                          <p>{props.champs.Vendor.Addresses[0].Country.Name.nameOnScreen}</p>
                          <select
                            className={styles.selectInAdresses}
                            name="CountryName"
                            id="CountryName"
                            disabled={!props.champs.Vendor.Addresses[0].Country.Name.modifiable}
                            onChange={(e) => handleChangeAdresse(index, "Country.Id", e.target.value)}
                            defaultValue={address.Country.Id}
                          >
                            {/* Option par défaut */}
                            <option value="" disabled hidden>
                              Sélectionner
                            </option>
                            {/* Options dynamiques */}
                            {props.combos.country
                              .sort((a, b) => {
                                if (a.name === address.Country.Name) {
                                  return -1;
                                }
                                if (b.name === address.Country.Name) {
                                  return 1;
                                }
                                return 0;
                              })
                              .map((country) => (
                                <option key={country.id} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                              <option value="0">Non renseigné</option>
                          </select>

                        </div>

                      </div>

                      {/*Ligne*/}
                      <div className={styles.firstRow}>

                        {/*Company*/}
                        <div  className={styles.modifiable}>
                          <p onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.Addresses[0].Company.maxChar} caractères max`}>{props.champs.Vendor.Addresses[0].Company.nameOnScreen}*</p>
                          <input type="text"
                            defaultValue={address.Company}
                            disabled={!props.champs.Vendor.Addresses[0].Company.modifiable}
                            onChange={(e) => handleChangeAdresse(index, "Company", e.target.value)}
                            maxLength={props.champs.Vendor.Addresses[0].Company.maxChar} />
                        </div>

                        {/*Adresse 1*/}
                        <div  className={styles.modifiable}>
                          <p  onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.Addresses[0].Adresse1.maxChar} caractères max`}>{props.champs.Vendor.Addresses[0].Adresse1.nameOnScreen}*</p>
                          <input type="text"
                            defaultValue={address.Adresse1}
                            disabled={!props.champs.Vendor.Addresses[0].Adresse1.modifiable}
                            onChange={(e) => handleChangeAdresse( index, "Adresse1", e.target.value) }
                            maxLength={props.champs.Vendor.Addresses[0].Adresse1.maxChar}/>
                        </div>

                        {/*Adresse 2*/}
                        <div  className={styles.modifiable}>
                          <p onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.Addresses[0].Adresse2.maxChar} caractères max`}>{props.champs.Vendor.Addresses[0].Adresse2.nameOnScreen}*</p>
                          <input type="text"
                            defaultValue={address.Adresse2}
                            disabled={!props.champs.Vendor.Addresses[0].Adresse2.modifiable}
                            onChange={(e) =>handleChangeAdresse(index, "Adresse2", e.target.value)}
                            maxLength={props.champs.Vendor.Addresses[0].Adresse2.maxChar}  />
                        </div>

                        {/*Zip Code*/}
                        <div  className={styles.modifiable}>
                          <p onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.Addresses[0].ZipCode.maxChar} caractères max`}>{props.champs.Vendor.Addresses[0].ZipCode.nameOnScreen}*</p>
                          <input type="text"
                            defaultValue={address.ZipCode}
                            disabled={!props.champs.Vendor.Addresses[0].ZipCode.modifiable}
                            onChange={(e) => handleChangeAdresse(index,"ZipCode", e.target.value ) }
                            maxLength={props.champs.Vendor.Addresses[0].ZipCode.maxChar}
                          />
                        </div>

                        {/*Ville*/}
                        <div  className={styles.modifiable}>
                          <p  onMouseOut={(e)=>{hideToolTip(e)}} onMouseOver={(event)=> {infoBulle(event)}} data-tooltip={`${props.champs.Vendor.Addresses[0].City.maxChar} caractères max`}>{props.champs.Vendor.Addresses[0].City.nameOnScreen}*</p>
                          <input type="text"
                            defaultValue={address.City}
                            disabled={!props.champs.Vendor.Addresses[0].City.modifiable}
                            onChange={(e) => handleChangeAdresse(index, "City", e.target.value)}
                            maxLength={props.champs.Vendor.Addresses[0].City.maxChar}/>
                        </div>
                      </div>


                    </div>

                  </div>
                )
              )}
            </div>
          </div>
          <div className={styles.vendorTableau}>
            <h3>Documents</h3>
            <table className={styles.tableau}>
              <thead>
                <tr>
                  <th>Type Id</th>
                  <th>Type Name</th>
                  <th>Type FIMandatory</th>
                  <th>FileName</th>
                  <th>FileType</th>
                </tr>
              </thead>
              <tbody>
                {props.demande.Request[0].Vendor.Documents.map(
                  (document, index) => (
                    <tr key={index}>
                      <td>{document.DocType.Id}</td>
                      <td>{document.DocType.Name}</td>
                      <td>{document.DocType.FlMandatory}</td>
                      <td>{document.FileName}</td>
                      <td>{document.FileType}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <Button text="Télécharger" onClick={() => downloadFolder(RequestId)}  loading={buttonDownloading}/>
          </div>
          <div className={styles.financialData}>
            <h3>Financial Data</h3>
            <div className={styles.financialDataDiv}>

              <div className={styles.modifiable}>
                <p>{props.champs.Vendor.FinancialData.ReglementCondition.Name.nameOnScreen}</p>
                <select
                className={styles.selectInFinancialData}
                  name="ReglementCondition"
                  id="ReglementCondition"
                  disabled={
                    !props.champs.Vendor.FinancialData.ReglementCondition.Name
                      .modifiable
                  }
                  onChange={handleChange(
                    setVendorFinancialDataReglementConditionId
                  )}
                  defaultValue={ props.demande.Request[0].Vendor.FinancialData.ReglementCondition.Id }
                >

                {/* Option par défaut */}
                  <option value="" disabled hidden>
                    Sélectionner
                  </option>

                  {/* Options dynamiques */}
                  {props.combos.conditionsRgmt.sort((a, b) => {
                    if (
                      a.name ===
                      props.demande.Request[0].Vendor.FinancialData
                        .ReglementCondition.Name
                    ) {
                      return -1;
                    }
                    if (
                      b.name ===
                      props.demande.Request[0].Vendor.FinancialData
                        .ReglementCondition.Name
                    ) {
                      return 1;
                    }
                    return 0;
                  }).map((reglementCondition) => (
                    <option
                      key={reglementCondition.id}
                      value={reglementCondition.id}
                    >
                      {reglementCondition.name}
                    </option>
                  ))}
                  <option value="0">Non renseigné</option>
                </select>
              </div>

              <div  className={styles.modifiable}>
                <p>{props.champs.Vendor.FinancialData.ReglementMode.Name.nameOnScreen}</p>
                <select
                  className={styles.selectInFinancialData}
                  name="ReglementMode"
                  id="ReglementMode"
                  disabled={!props.champs.Vendor.FinancialData.ReglementMode.Name.modifiable}
                  onChange={handleChange(setVendorFinancialDataReglementModeId)}
                  defaultValue={props.demande.Request[0].Vendor.FinancialData.ReglementMode.Id}
                >
                  {/* Option par défaut */}
                  <option value="" disabled hidden>
                    Sélectionner
                  </option>
                  {/* Options dynamiques */}
                  {props.combos.modeRgmt
                    .sort((a, b) => {
                      if (
                        a.name ===
                        props.demande.Request[0].Vendor.FinancialData.ReglementMode.Name
                      ) {
                        return -1;
                      }
                      if (
                        b.name ===
                        props.demande.Request[0].Vendor.FinancialData.ReglementMode.Name
                      ) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((reglementMode) => (
                      <option
                        key={reglementMode.id}
                        value={reglementMode.id}
                      >
                        {reglementMode.name}
                      </option>
                    ))}
                    <option value="0">Non renseigné</option>
                </select>

              </div>

              <div  className={styles.modifiable}>
                <p>{props.champs.Vendor.FinancialData.Type_TVA_Appliquee.Name.nameOnScreen}</p>
                <select className={styles.selectInFinancialData} name="TVA" id="TVA" disabled={!props.champs.Vendor.FinancialData.Type_TVA_Appliquee.Name.modifiable} onChange={handleChange(setVendorFinancialDataType_TVA_AppliqueeId)} defaultValue={props.demande.Request[0].Vendor.FinancialData.Type_TVA_Appliquee.Id}>
                  
                  {/* Option par défaut */}
                  <option value="" disabled hidden>
                    Sélectionner
                  </option>

                  {/* Options dynamiques */}                  
                  {props.combos.Tva.sort((a, b) => {
                    if (
                      a.name ===
                      props.demande.Request[0].Vendor.FinancialData
                        .Type_TVA_Appliquee.Name
                    ) {
                      return -1;
                    }
                    if (
                      b.name ===
                      props.demande.Request[0].Vendor.FinancialData
                        .Type_TVA_Appliquee.Name
                    ) {
                      return 1;
                    }
                    return 0;
                  }).map((tva) => (
                    <option
                      key={tva.id}
                      value={tva.id}
                    >
                      {tva.name}
                    </option>
                  ))}
                  <option value="0">Non renseigné</option>
                </select>
              </div>
            </div>  
          </div>
        </div>

        <div className={styles.valider}>
        {statusIsSubmit && role ==='media-servicing' && (<Button text="Valider" onClick={handleButtonValider}  loading={buttonLoading} />)}
      </div>

      </div>

      {showPopup && <PopupAlert message={messagePopup} handleClosePopup={handleClosePopup}/>}
      {idPopup === "confirmSubmitAgain" && (<PopupConfirm message={messagePopup} actionOnConfirm={updateStatusInDb} actionOnClose={cancelStatusUpdate} />)}
      
    </div>
  );
}

export default OneDemandeCarat;
