import Status from './Status';
import Vendor from './Vendor';

class Request {
  constructor(data) {
    this.Id = data.Id;
    this.RequestType = data.RequestType;
    this.RequestDate = data.RequestDate;
    this.UserRequest = data.UserRequest;
    this.RequestStatus =  data.RequestStatus
    this.Vendor = data.Vendor;
  }
}

export default Request;
