import React, { useState, useRef } from "react";
import styles from "./Reponse.module.css";
import { SaveResponse } from "../../Services/demandes";
import Button from "../Button/Button";
import cancelIcon from "../../assets/cancel.png";
import cross_svg from "../../assets/cross.svg";
import { useNavigate } from 'react-router-dom'; // Importez useNavigate
import PopupAlert from "../PopUp/PopupAlert/PopupAlert";

// Fonction pour parser les emails
function parseEmailString(emailString) {
    return emailString
        .split(/[,;]/)  // Split on comma or semicolon
        .map(email => email.trim())  // Trim whitespace from each email
        .filter(email => email.length > 0);  // Filter out any empty strings
}

function Reponse({ subject, demandeId, destinataire, ccList, cciList , updateDemandData}) {
    const [body, setBody] = useState("");
    const [cc, setCc] = useState('');
    const [cci, setCci] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [files, setFiles] = useState([]);
    const dropzoneRef = useRef(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('')
    const [showPopupAlert, setShowPopupAlert] = useState(false)
    const navigate = useNavigate();
    
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleClosePopup=()=>{
        setMessageAlert('')
        setShowPopupAlert(false)
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropzoneRef.current) {
            dropzoneRef.current.style.border = '2px dashed cyan';
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropzoneRef.current) {
            dropzoneRef.current.style.border = '2px dashed #ccc';
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropzoneRef.current) {
            dropzoneRef.current.style.border = '2px dashed #ccc';
        }

        const droppedFiles = Array.from(e.dataTransfer.files);
        handleFiles(droppedFiles);
    };

    const handleFiles = (filesArray) => {
        const fileReaders = [];
        const fileList = [];
        
        filesArray.forEach(file => {
            const reader = new FileReader();
            fileReaders.push(reader);
    
            reader.onload = (e) => {
                const base64Content = e.target.result.match(/,(.*)$/)[1]; // Extract base64 content after comma
                fileList.push({ name: file.name, content: base64Content });
                if (fileList.length === filesArray.length) {
                    setFiles(prevFiles => [...prevFiles, ...fileList]);
                }
            };
            reader.readAsDataURL(file); // Read the file as Data URL to get base64 encoded string
        });
    };

    const handleCc = (e) => {
        setCc(e.target.value);
    };

    const handleCci = (e) => {
        setCci(e.target.value);
    };

    const handleSave = () => {
        setButtonLoading(true)
        const request = {
            user: {
                email: sessionStorage.getItem("currentMail")
            },
            email: {
                demandeid: demandeId,
                from: sessionStorage.getItem("currentMail"),
                to: destinataire,
                cclist: parseEmailString(cc),
                ccilist: parseEmailString(cci),
                subject: subject,
                body: body,
                fileslist: files.map(file => ({ filename: file.name, filecontent: file.content })),
                rmlist: ''
            },
        };
    
        SaveResponse(request)
            .then(response => {
                if (!response.ok) {
                    const messages = `An error has occurred: ${response.status}`;
                    setButtonLoading(false)
                    setMessageAlert("Une erreur est survenue. La réponse n'a pas été enregistrée")
                    setShowPopupAlert(true)
                    throw new Error(messages);
                }
                return response.json(); // assuming the response needs to be parsed as JSON
            })
            .then(data => {
                const jsonData = JSON.parse(data);
                setButtonLoading(false)

                console.log("retour du back après Save Response() : ")
                console.log(jsonData) 

                if(jsonData.status.code === 422){
                    setMessageAlert("Une erreur est survenue lors de l'enregistrement de la réponse.")
                } else{
                    if(jsonData.status.message.includes("Error")){
                        setMessageAlert("La réponse a bien été enregistrée ; une erreur est survenue lors de la notification email.")
                    }
                    else{
                        setMessageAlert("Réponse bien enregistrée")
                    }
                }
                
                setShowPopupAlert(true);

                console.log("appel de l'update des données de la demande")
                updateDemandData();
                
            })
            .catch(error => {
                setButtonLoading(false)
                setMessageAlert("Une erreur est survenue.")
                setShowPopupAlert(true)
                console.error("Error saving response:", error);
            });
    };
    

    return (
        <div className={styles.container}>
            <div className={styles.bottomContainer}>
                <label>Destinataire</label>
                <input
                    type="email"
                    className={styles.input}
                    value={destinataire}
                    readOnly={true}
                />

                <label>Cc</label>
                <input
                    type="email"
                    className={styles.input}
                    placeholder="Ajouter cc"
                    value={cc}
                    onChange={handleCc}
                />
                
                <label>Cci</label>
                <input
                    type="email"
                    className={styles.input}
                    placeholder="Ajouter cci"
                    value={cci}
                    onChange={handleCci}
                />
                
                <label>Message</label>
                <textarea
                    className={styles.textarea}
                    placeholder="Saisissez votre message"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                ></textarea>

                <div className={styles.dragAndDrop}>
                    <label> Fichiers (2 mo max par document) </label>

                    <div
                        className={styles.dropzone}
                        ref={dropzoneRef}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <input
                            type="file"
                            onChange={(e) => handleFiles(Array.from(e.target.files))}
                            multiple
                        />

                        <ul className={styles.filesList}>
                            {files.map((file, index) => (
                                <li key={index}>
                                    <span className={styles.fileName}>{file.name}</span>
                                    <img src={cancelIcon} alt="annuler" className={styles.cancelFile} onClick={() => setFiles(files.filter((_, i) => i !== index))} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {/*<div className={styles.button} onClick={handleSave}>Envoyer</div>*/}
            <Button text="Envoyer" onClick={handleSave} loading={buttonLoading}/>


            {showPopupAlert && (
                <PopupAlert message={messageAlert} handleClosePopup={handleClosePopup}/>
                /*<div className={styles.popup}>
                    <span className={styles.closeIcon} onClick={() => setShowPopup(false)}>
                        <img src={cross_svg} alt="Close" className={styles.closeIconImg} />
                    </span>
                    <p>Réponse : {respData}</p>
                </div>*/
            )}
        </div>
    );
}

export default Reponse;
