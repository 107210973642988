import React, { useState, useEffect } from 'react';
import cross from '../../assets/cross.svg';
import styles from './Mailing.module.css';
import svg_file from '../../assets/File.svg';
import Add_round from '../../assets/Add_round.svg';
import Time from '../../assets/Time.svg';
import Message from '../Message/Message';
import Download from '../../assets/Download.svg';
import Chat_fill from '../../assets/Chat_fill.svg';
import Back from '../../assets/Back.svg';
import Reponse from '../Reponse/Reponse';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString('fr-FR', options);
}

function fromArrayToString(array) {
    return array.join(', ');
}

function getEmailWithHighestId(demandeslist) {
    if (!demandeslist || demandeslist.length === 0) {
        return null;
    }

    const emailslist = demandeslist[0].emailslist;
    if (!emailslist || emailslist.length === 0) {
        return null;
    }

    let highestIdEmail = emailslist[0];

    for (const email of emailslist) {
        if (email.id > highestIdEmail.id) {
            highestIdEmail = email;
        }
    }

    return highestIdEmail;
};

function changeBody(registers, bodies, activeRegister) {
    Array.from(registers.children).forEach((el, i) => {
        if (bodies.children[i]) {
            bodies.children[i].style.display = el === activeRegister ? 'block' : 'none';
        }

        el.setAttribute('aria-expanded', el === activeRegister ? 'true' : 'false');
    });
}

function Mailing({ chatClose, oneDemand, updateData }) {
    const [showFiles, setShowFiles] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    
    const handleClose = () => {
        chatClose();
    };

    const changeTab = (index) => {
        setActiveTab(index);
    };

    const highestIdEmail = getEmailWithHighestId(oneDemand.results.demandeslist);

    const downloadFile = (fichierEnBase64, filename, mimetype) => {
        const file = fileBuilt(fichierEnBase64, mimetype);
        // Download du fichier
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element);
        element.click();
    };

    const fileBuilt = (fichierEnBase64, mimetype) => {
        // Création du fichier
        let filecontent = atob(fichierEnBase64);
        var buffer = new ArrayBuffer(filecontent.length);
        var bufferView = new Uint8Array(buffer);

        for (var i = 0; i < filecontent.length; i++) {
            bufferView[i] = filecontent.charCodeAt(i);
        }
        return new Blob([bufferView], { type: mimetype });
    }

    const filesList = oneDemand?.results?.demandeslist?.[0]?.fileslist;

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top

        Array.from(document.querySelectorAll('.tabs')).forEach((tab_container, TabID) => {
            const registers = tab_container.querySelector('.tab-registers');
            const bodies = tab_container.querySelector('.tab-bodies');

            Array.from(registers.children).forEach((el, i) => {
                el.setAttribute('aria-controls', `${TabID}_${i}`);
                bodies.children[i]?.setAttribute('id', `${TabID}_${i}`);

                el.addEventListener('click', (ev) => {
                    let activeRegister = registers.querySelector('.active-tab');
                    activeRegister.classList.remove('active-tab');
                    activeRegister = el;
                    activeRegister.classList.add('active-tab');
                    changeBody(registers, bodies, activeRegister);
                });
            });
        });
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.rightContainer}>
                <div className={styles.rightContainerTop}>
                    <h1> {oneDemand.results.demandeslist[0].regiesupportlib} </h1>
                    <img src={cross} id="closeCross" alt="close" onClick={handleClose} />
                </div>

                <hr />

                <div className={styles.rightContainerMiddle}>
                    <div className={styles.champ}>
                        <label>Demande n°</label>
                        <p> {oneDemand.results.demandeslist[0].id} </p>
                    </div>

                    <div className={styles.champ}>
                        <label>Créée le </label>
                        <p>{formatDate(oneDemand.results.demandeslist[0].dateinit)} </p>
                    </div>

                    <div className={styles.champ}>
                        <label>Demandeur</label>
                        <p> {oneDemand.results.demandeslist[0].demandeur} </p>
                    </div>

                    <div className={styles.champ}>
                        <label>Statut</label>
                        <p> {oneDemand.results.demandeslist[0].status} </p>
                    </div>
                </div>

                <div className={styles.rightContainerBottom}></div>
            </div>

            <div className={styles.leftContainer}>
                <div className={styles.messageContainer}>
                    <div className={styles.tabs}>
                        <div className={styles['tabsOnglet']}>
                            <button
                                className={`${activeTab === 0 ? styles['active-tab-1'] : ''}`}
                                onClick={() => changeTab(0)}><img src={Chat_fill} alt="Message" /> Messages</button>

                            <button
                                className={`${activeTab === 1 ? styles['active-tab-2'] : ''}`}
                                onClick={() => changeTab(1)}><img src={svg_file} alt="Fichiers" /> Fichiers</button>

                            <button
                                className={`${activeTab === 2 ? styles['active-tab-3'] : ''}`}
                                onClick={() => changeTab(2)}><img src={Back} alt="Répondre" /> Répondre</button>
                        </div>

                        <div className={styles['tab-bodies']}>
                            <div style={{ display: activeTab === 0 ? 'block' : 'none', backgroundColor: '#cdfcfa' }}>
                                {!showFiles &&
                                    <div className={styles.messageDisplay}>
                                        {oneDemand.results.demandeslist[0].emailslist.map((message, index) => (
                                            <Message key={index} messages={message} />
                                        ))}
                                    </div>
                                }
                            </div>
                            <div style={{ display: activeTab === 1 ? 'block' : 'none', backgroundColor: '#cdfcfa' }}>
                                <div className={styles.filesDisplay}>
                                    <ul>
                                        {filesList && filesList.length > 0 ? (
                                            filesList.map(file => (
                                                <li key={file.fileid}>
                                                    <span>{file.filename}</span>
                                                    <img src={Download} alt="download" onClick={() => downloadFile(file.filecontent, file.filename, file.mimetype)} />
                                                </li>
                                            ))
                                        ) : (
                                            <li>Aucun fichier disponible</li>
                                        )}
                                    </ul>
                                </div>
                            </div>

                            <div className={styles.repondreDisplay} style={{ display: activeTab === 2 ? 'block' : 'none', backgroundColor: '#cdfcfa' }}>
                                {highestIdEmail && (
                                    <Reponse
                                        subject={oneDemand.results.demandeslist[0].objet}
                                        demandeId={oneDemand.results.demandeslist[0].id}
                                        destinataire={highestIdEmail.from}
                                        ccList={fromArrayToString(highestIdEmail.cclist)}
                                        cciList={fromArrayToString(highestIdEmail.ccilist)}
                                        updateDemandData={updateData}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mailing;
