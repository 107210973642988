import React, {useState} from "react";
import styles from "./Filter.module.css";
import cross_svg from "../../assets/cross.svg";
import Button from "../Button/Button";
import { GetDemands_Dentsu } from "../../Services/demandes";

function FilterCarat({filterClose, filterChange, loadingState}) {
    const [vendor, setVendor] = useState('');
    const [status, setStatus] = useState('');
    const [nombreResultat, setNombreResultat] = useState(0);
    const [tri, setTri] = useState('');

    const close = () => {
        filterClose();
    }

    const handleFilterChange = () => {
        filterChange(vendor, status, tri, nombreResultat);
    }

    return (
        <div className={styles.filter}>

            <div className={styles.encartBlanc}>

                <div className={styles.topFilter}>
                    <h1>Recherche Carat</h1>
                    <div className={styles.close} >
                        <img src={cross_svg} alt="close" onClick={close} />
                    </div>

                </div>
            
                <div className={styles.filterContainer}>
                    <div className={styles.filterDiv}>
                        <label htmlFor="vendor">Nom du fournisseur (Vendor)</label>
                        <input type="text" id="vendor" name="vendor" onChange={(event) => setVendor(event.target.value)} />
                    </div>

                    <div className={styles.filterDiv}>
                        <label htmlFor="status">Statut</label>
                        <select id="status" name="status" onChange={(event) => setStatus(event.target.value)}>
                            <option value=''>Sélectionner</option>
                            <option value="Submit">Submit</option>
                            <option value="Accept">Accept</option>
                            <option value="Return For Update">Return For Update</option>
                            <option value="Sended">Sended</option>
                            <option value="Waiting Completed">Waiting Completed</option>
                            <option value="Completed">Completed</option>
                            <option value="Cancel">Cancel</option>
                            <option value="Canceled">Canceled</option>
                            <option value="Structural Error">Structural Error</option>
                        </select>
                    </div>

                    <div className={styles.filterDiv}>
                        <label htmlFor="nombreResultat">Nombre de résultats</label>
                        <select id="nombreResultat" name="nombreResultat" onChange={(event) => setNombreResultat(event.target.value)}>
                            <option value=''>Sélectionner</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>

                    <div className={styles.filterDiv}>
                        <label htmlFor="tri">Tri</label>
                        <select id="tri" name="tri" onChange={(event) => setTri(event.target.value)}>
                            <option value=''>Sélectionner</option>
                            <option value="Ids Ascendants">Ids Ascendants</option>
                            <option value="Ids Descendants">Ids Descendants</option>
                        </select>
                    </div>

                </div>
                <Button onClick={handleFilterChange} text="Filtrer" loading={loadingState} />

            </div>

        </div>
    );
}

export default FilterCarat;