import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';
import styles from './HomePage.module.css';
import send_fill from '../../assets/Send_fill.svg';
import search from '../../assets/Search_fill.svg';
import PopUpSend from '../PopUp/PopUpSend';
import PopUpConnexion from '../PopUp/PopUpConnexion';
import Rgpd from '../RGPD/BannerRgpd';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate

function HomePage({ onPageChange }) {
  const [isRequesting, setIsRequesting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [showRgpd, setShowRgpd] = useState(false);
const [loadingState, setLoadingState] = useState('loading')

  useEffect(() => {
    const hasAcceptedRgpd = localStorage.getItem('hasAcceptedRgpd');
    if (!hasAcceptedRgpd) {
      setShowRgpd(true);
    }
  }, []);

  const handleCloseRequesting = () => {
    setIsRequesting(false);
  };

  const handleCloseSearching = () => {
    setIsSearching(false);
  };

  const handleDemandeClick = () => {
    setIsSearching(false);
    setIsRequesting(true);
  };

  const handleRechercheClick = () => {
    setIsRequesting(false);
    setIsSearching(true);
  };

  const handleChangePage = (page) => {
    onPageChange(page);
  };

  const handleAcceptRgpd = () => {
    localStorage.setItem('hasAcceptedRgpd', 'true');
    setShowRgpd(false);
  };

  return (
    <div className={styles.divContainer}>
      {showRgpd && <Rgpd onClose={handleAcceptRgpd} />}
      {isRequesting && <PopUpSend onClose={handleCloseRequesting} pageChange={handleChangePage} />}
      {isSearching && <PopUpConnexion onClose={handleCloseSearching} pageChange={handleChangePage} />}
      <h1 className={styles.h1}>Gestion de la base Support</h1>
      <p>
        Bienvenue sur la plateforme ABS d'administration de la base Support.
        Celle-ci vous permet de demander l'ajout ou la modification des Supports-Régies.
      </p>
      <p> Que souhaitez-vous faire ? </p> 
      <div className={styles.divDemande}>
        <div className={styles.insideDemande}>
          <img src={send_fill} alt="Logo" style={{ width: '10%' }} />
          <p> Vous pouvez effectuer une demande de création, de modification sur un support ou une régie, ou sur leur lien. Une identification simple sans mot de passe suffit. </p>
          <Button text="Effectuer une demande" onClick={handleDemandeClick} />
        </div>
        <div className={styles.insideDemande}>
          <img src={search} alt="Logo" style={{ width: '10%' }} />
          <p> Recherche sur les nouvelles demandes, les demandes en cours ou encore les demandes traitées. Une authentification complète avec mot de passe est requise. </p>
          <Button text="Effectuer une recherche" onClick={handleRechercheClick} />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
