import React, { useState } from 'react';
import styles from './Message.module.css';
import Back from '../../assets/Back.svg';
import Reponse from '../Reponse/Reponse';

function formatDate ( dateString ) {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleDateString('fr-FR', options);
}

function Message({ messages, index }) {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className={styles.container}>

      <div className={styles.topContainer}>
        <div>

          {/*Date*/}
          <div className={styles.champ}>
            <label>Date</label>
            <p>{formatDate(messages.date)}</p>
          </div>

          {/*Expediteur*/}
          <div className={styles.champ}>
            <label>De</label>
            <p>{messages.from}</p>
          </div>

          {/*Destinataire*/}
          <div className={styles.champ}>
            <label>À</label>
            <p>{messages.to}</p>
          </div>

          {/*Cc*/}
          <div className={styles.champ}>
            <label>Cc</label>
            <p>
              {messages.cclist.map((item, index) => (
                <span key={index}>{item}; </span>
              ))}
            </p>

          </div>

          {/*Sujet*/}
          <div className={styles.champ}>
            <label>Sujet</label>
            <p>{messages.subject}</p>
          </div>

          {/*PJ*/}
          <div className={styles.champ}>
            <label>PJ</label>
            <p>{messages.fileslist.length}</p>
          </div>

          {/*Corps*/}
          <div className={styles.champ}>
            <label>Corps</label>
            <p>{messages.body}</p>
          </div>
        </div>

        {/*<img src={Back} alt="Répondre" onClick={togglePopup} />*/}

      </div>

      <div className={styles.bottomContainer}>


      </div>


      {showPopup && (
        <div>
          <hr />
        <Reponse messages={messages} closeReponse={togglePopup} />
        </div>
      )}
    </div>
  );
}

export default Message;
