import React, { useState, useEffect } from 'react';
import cross from '../../assets/cross.svg';
import styles from './MailingFromUrl.module.css';
import svg_file from '../../assets/File.svg';
import Add_round from '../../assets/Add_round.svg';
import Time from '../../assets/Time.svg';
import Message from '../Message/Message';
import Download from '../../assets/Download.svg';
import Chat_fill from '../../assets/Chat_fill.svg';
import Back from '../../assets/Back.svg';
import Reponse from '../Reponse/Reponse';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString('fr-FR', options);
}

function fromArrayToString(array) {
    return array.join(', ');
}

function getEmailWithHighestId(demandeslist) {
    if (!demandeslist || demandeslist.length === 0) {
        return null;
    }

    const emailslist = demandeslist[0].emailslist;
    if (!emailslist || emailslist.length === 0) {
        return null;
    }

    let highestIdEmail = emailslist[0];

    for (const email of emailslist) {
        if (email.id > highestIdEmail.id) {
            highestIdEmail = email;
        }
    }

    return highestIdEmail;
}

function Mailing({ showCloseCross, chatClose, oneDemand, updateData }) {
    const [showFiles, setShowFiles] = useState(false);
    const [showCross, setShowCross] = useState(showCloseCross);
    const [from, setFrom] = useState('');
    const [cc, setCc] = useState('');
    const [cci, setCci] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (oneDemand && oneDemand.results && oneDemand.results.demandeslist && oneDemand.results.demandeslist.length > 0) {
            const highestIdEmail = getEmailWithHighestId(oneDemand.results.demandeslist);
            if (highestIdEmail) {
                setFrom(highestIdEmail.from);
                setCc(fromArrayToString(highestIdEmail.cclist));
                setCci(fromArrayToString(highestIdEmail.ccilist));
            }
        }
    }, [oneDemand]);

    const handleClose = () => {
        chatClose();
    };

    const changeTab = (index) => {
        setActiveTab(index);
    };

    const downloadFile = (fichierEnBase64, filename, mimetype) => {
        const file = fileBuilt(fichierEnBase64, mimetype);
        // Download du fichier
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element);
        element.click();
    };

    const fileBuilt = (fichierEnBase64, mimetype) => {
        // Création du fichier
        let filecontent = atob(fichierEnBase64);
        var buffer = new ArrayBuffer(filecontent.length);
        var bufferView = new Uint8Array(buffer);

        for (var i = 0; i < filecontent.length; i++) {
            bufferView[i] = filecontent.charCodeAt(i);
        }
        const file = new Blob([bufferView], { type: mimetype });
        return file;
    };

    const demandeslist = oneDemand?.results?.demandeslist || [];
    const filesList = demandeslist[0]?.fileslist || [];
    const emailsList = demandeslist[0]?.emailslist || [];

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top

        Array.from(document.querySelectorAll('.tabs')).forEach((tab_container, TabID) => {
            const registers = tab_container.querySelector('.tab-registers');
            const bodies = tab_container.querySelector('.tab-bodies');

            Array.from(registers.children).forEach((el, i) => {
                el.setAttribute('aria-controls', `${TabID}_${i}`);
                bodies.children[i]?.setAttribute('id', `${TabID}_${i}`);

                el.addEventListener('click', (ev) => {
                    let activeRegister = registers.querySelector('.active-tab');
                    activeRegister.classList.remove('active-tab');
                    activeRegister = el;
                    activeRegister.classList.add('active-tab');
                    changeBody(registers, bodies, activeRegister);
                });
            });
        });
    }, []);

    function changeBody(registers, bodies, activeRegister) {
        Array.from(registers.children).forEach((el, i) => {
            if (bodies.children[i]) {
                bodies.children[i].style.display = el === activeRegister ? 'block' : 'none';
            }

            el.setAttribute('aria-expanded', el === activeRegister ? 'true' : 'false');
        });
    }

    return (
        <div className={styles.container}>
            {/*Titre*/}
            <div className={styles.demandeTitle}>
                <h1>{demandeslist[0]?.regiesupportlib}</h1>
                <h3>{demandeslist[0]?.objet}</h3>
            </div>

            <div className={styles.contenuDemande}>
                {/*LEFT CONTAINER*/}
                <div className={styles.leftContainer}>
                    <div className={styles.leftContainerMiddle}>
                        {/*N° Demande*/}
                        <div className={styles.champ}>
                            <label>Demande n°</label>
                            <p>{demandeslist[0]?.id}</p>
                        </div>

                        {/*Date*/}
                        <div className={styles.champ}>
                            <label>Créée le</label>
                            <p>{formatDate(demandeslist[0]?.dateinit)}</p>
                        </div>

                        {/*Demandeur*/}
                        <div className={styles.champ}>
                            <label>Par</label>
                            <p>{demandeslist[0]?.demandeur}</p>
                        </div>

                        {/*Statut*/}
                        <div className={styles.champ}>
                            <label>Statut</label>
                            <p>{demandeslist[0]?.status}</p>
                        </div>
                    </div>
                </div>

                {/*RIGHT CONTAINER*/}
                <div className={styles.rightContainer}>
                    <div className={styles.messageContainer}>
                        <div className={styles.tabs}>
                            <div className={styles['tabsOnglet']}>
                                {/*MESSAGE ONGLET*/}
                                <button
                                    className={`${activeTab === 0 ? styles['active-tab-1'] : ''}`}
                                    onClick={() => changeTab(0)}
                                >
                                    <img src={Chat_fill} alt="Message" /> Messages
                                </button>

                                {/*FICHIERS ONGLET*/}
                                <button
                                    className={`${activeTab === 1 ? styles['active-tab-2'] : ''}`}
                                    onClick={() => changeTab(1)}
                                >
                                    <img src={svg_file} alt="Fichiers" /> Fichiers
                                </button>

                                {/*REPONDRE ONGLET*/}
                                <button
                                    className={`${activeTab === 2 ? styles['active-tab-3'] : ''}`}
                                    onClick={() => changeTab(2)}
                                >
                                    <img src={Back} alt="Répondre" /> Répondre
                                </button>
                            </div>

                            <div className={styles['tab-bodies']}>
                                <div style={{ display: activeTab === 0 ? 'block' : 'none', backgroundColor: '#cdfcfa' }}>
                                    {!showFiles && (
                                        <div className={styles.messageDisplay}>
                                            {emailsList.map((message, index) => (
                                                <Message key={index} messages={message} />
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div style={{ display: activeTab === 1 ? 'block' : 'none', backgroundColor: '#cdfcfa' }}>
                                    <div className={styles.filesDisplay}>
                                        <ul>
                                        {filesList && filesList.length > 0 ? (
                                            filesList.map(file => (
                                                <li key={file.fileid}>
                                                    <span>{file.filename}</span>
                                                    <img src={Download} alt="download" onClick={() => downloadFile(file.filecontent, file.filename, file.mimetype)} />
                                                </li>
                                            ))
                                        ) : (
                                            <li>Aucun fichier disponible</li>
                                        )}
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles.repondreDisplay} style={{ display: activeTab === 2 ? 'block' : 'none', backgroundColor: '#cdfcfa' }}>
                                    <Reponse
                                        subject={demandeslist[0]?.objet}
                                        demandeId={demandeslist[0]?.id}
                                        destinataire={from}
                                        ccList={cc}
                                        cciList={cci}
                                        updateDemandData={updateData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mailing;
