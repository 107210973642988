import React, { useEffect, useState } from "react";
import styles from "./SelectEmail.module.css";
import Button from "../Button/Button";
import { GetEmails, GetValidator, CheckConnectionHigh } from "../../Services/identification_api";
import { ResponseClient } from "../../Services/demandes";
import LoadingAnimation from "../LoadingIcon/LoadingIcon";
import { useNavigate, useParams } from 'react-router-dom';
import PopupAlert from "../PopUp/PopupAlert/PopupAlert";

function SelectEmail(props) {
  const [selectedEmail, setSelectedEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [passwordNeeded, setPasswordNeeded] = useState(false);
  const [password, setPassword] = useState("");
  const [isEnterKeyListenerActive, setIsEnterKeyListenerActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const { uidValue } = useParams(); // Assurez-vous que uidValue est récupéré correctement
  const [messageAlert, setMessageAlert] = useState('')
  const [showPopupAlert, setShowPopupAlert] = useState(false)

  useEffect(() => {

    if (!uidValue) {
      setMessageAlert("L'url est incomplète")
      setShowPopupAlert(true)
      console.error("UID value is missing from the URL");
      return;
    }

    setIsLoading(true);
    const fetchEmails = async () => {
      console.log(" dans fetchEmails, uidValue :" + uidValue)
      try {
        const response = await GetEmails(uidValue);
        if (!response.ok) {
          throw new Error(`An error has occurred: ${response.status}`);
        }
        const data = await response.json();
        const result = JSON.parse(data);
        const parsedEmails = result.data.split(',').filter(email => email.trim() !== '');
        setEmails(parsedEmails);
        setIsLoading(false);
      } catch (error) {
        console.error(error.message);
        setIsLoading(false);
      }
    };
    fetchEmails();

    return () => {
      setIsEnterKeyListenerActive(false);
    };
  }, [uidValue]);

  useEffect(() => {
    if (isEnterKeyListenerActive) {
      const handleEnterKey = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          if (passwordNeeded) {
            handlePasswordSend();
          } else {
            handleValider();
          }
        }
      };

      document.addEventListener("keydown", handleEnterKey);

      return () => {
        document.removeEventListener("keydown", handleEnterKey);
      };
    }
  }, [isEnterKeyListenerActive, passwordNeeded]);

  const handleChange = (event) => {
    setSelectedEmail(event.target.value);
    handleValider();
  };

  const handleClosePopup =() =>{
    setMessageAlert("")
    setShowPopupAlert(false)
    setButtonLoading(false)
  }


  const handleValider = () => {
    console.log(" dans handle Valider, uidValue :" + uidValue)

    const mail = document.getElementById("email").value;
    setButtonLoading(true);

    GetValidator(mail)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`An error has occurred: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const result = JSON.parse(data);
        setButtonLoading(false);

        if (result.data.includes(mail)) { // besoin de mdp
          setPasswordNeeded(true);
          setIsEnterKeyListenerActive(true);
          return Promise.resolve(null);
        } else { // si pas besoin d'un mot de passe

          console.log(" dans handle valider après get validator , uidValue :" + uidValue)

          return ResponseClient(uidValue, mail);
        }
      })
      .then((responseClient) => {
        if (!responseClient) {
          return;
        }

        if (!responseClient.ok) {
          throw new Error(`An error has occurred: ${responseClient.status}`);
        }

        return responseClient.json();
      })
      .then((dataClient) => {
        if (dataClient) {
          const resultClient = JSON.parse(dataClient);
          sessionStorage.setItem('currentMail', mail);
          sessionStorage.setItem('demandeId', resultClient.results.messageslist[0].demandeid);
          setPasswordNeeded(false);
          navigate('/loadingMailing');
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordSend = async () => {
    try {
      const mail = selectedEmail;
      const pwd = document.getElementById("pwd").value;
      setButtonLoading(true);
      const response = await CheckConnectionHigh(mail, pwd);

      if (!response.ok) {
        setButtonLoading(false);
        
        throw new Error(`An error has occurred: ${response.status}`);
      }
      const data = await response.json();
      const result = JSON.parse(data);


      if (result.status.code === 200) {
        const responseClient = await ResponseClient(uidValue, mail);
        if (!responseClient.ok) {
          setButtonLoading(false);
          setMessageAlert("Erreur lors de l'authentification")
          setShowPopupAlert(true)
          throw new Error(`An error has occurred: ${responseClient.status}`);
        }
        const dataClient = await responseClient.json();
        const resultClient = JSON.parse(dataClient);
        setButtonLoading(false);

        sessionStorage.setItem('currentMail', mail);
        sessionStorage.setItem('demandeId', resultClient.results.messageslist[0].demandeid);
        setPasswordNeeded(false);
        navigate('/loadingMailing')
      }
      else{
        setButtonLoading(false)
        setMessageAlert("Erreur lors de l'authentification")
        setShowPopupAlert(true)
      }
    } catch (error) {
      setMessageAlert("Erreur lors de l'authentification")
      setShowPopupAlert(true)

      setButtonLoading(false);
      console.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      {showPopupAlert && <PopupAlert message={messageAlert} handleClosePopup={handleClosePopup}/>}
      {isLoading && <LoadingAnimation />}
      {!isLoading && (
        <div className={styles.popUp}>
          <h1 className={styles.h1}>Votre identité</h1>
          <div className={styles.inputDiv}>
            <select id="email" className={styles.selectInput} onChange={handleChange} value={selectedEmail}>
              <option disabled value="">Sélectionner votre email</option>
              {emails.map((email, index) => (
                <option key={index} value={email.trim()}>{email.trim()}</option>
              ))}
            </select>

            {passwordNeeded && (
              <input
                id="pwd"
                type="password"
                placeholder="Entrez votre mot de passe"
                onChange={changePassword}
                className={styles.passwordInput}
              />
            )}

            <div className={styles.divButton}>
              <Button text='Valider' onClick={passwordNeeded ? handlePasswordSend : handleValider} loading={buttonLoading} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectEmail;
