
const apiUrl = process.env.REACT_APP_MODE === 'PROD' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_TEST ;
const apikey = process.env.REACT_APP_API_KEY

export async function NewDemand(email, regiesupportlib, subject, body, files) {    
    const requestData = {
        "url": `${apiUrl}/api/values/NewDemand`,
        "method": "POST",
        "contenttype": "application/json",
        "apikey": apikey,
        "request": {
            "user": {
                "email": email
            },
            "email": {
                "regiesupportlib": regiesupportlib,
                "subject": subject,
                "body": body,
                "fileslist": files
            }
        }
    };

    const response = await fetch(requestData.url, {
        method: requestData.method,
        headers: {
            'Content-Type': requestData.contenttype,
            'apikey': requestData.apikey,
        },
        body: JSON.stringify(requestData.request)
    });

    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }

    console.log(response);
    return response;
}

export async function GetDemandsAbs(request) {
    console.log("dans Get Demandes Abs") 

    console.log(apikey)
    console.log(`${apiUrl}/api/values/GetDemands_SuppAsk`)
    console.log(request)   

const response = await fetch(`${apiUrl}/api/values/GetDemands_SuppAsk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
        body: JSON.stringify(request),
    });
console.log(" fin de requete")
console.log(response)

    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function GetOneDemandAbs(demandeid) {
    
    const response = await fetch( `${apiUrl}/api/values/GetDemand_SuppAsk?demandeid=${demandeid}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function SaveResponse(request) {

    const response = await fetch(`${apiUrl}/api/values/SaveResponse`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
        body: JSON.stringify(request),
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function ResponseClient(uid, email) {
    const response = await fetch( `${apiUrl}/api/values/ResponseClient?uidValue=${uid}&&user=${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

// dentsu

export async function GetDemands_Dentsu(search) {
    console.log("api url : "+ apiUrl)
    const response = await fetch(`${apiUrl}/api/dentsu/GetDemands`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
        body: JSON.stringify(search),
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function GetComboData(table) {
    const response = await fetch(`${apiUrl}/api/dentsu/GetComboData?table=${table}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function GetFilesDentsu(demandeid) {
    const response = await fetch(`${apiUrl}/api/dentsu/GetFiles?demandeid=${demandeid}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function GetFilesAndLogsDentsu(demandeid) {
    const response = await fetch(`${apiUrl}/api/dentsu/GetFilesAndLogs?demandeid=${demandeid}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function ExportExcel(request, cible) {
    let url = `${apiUrl}/api/`+cible+`/ExportExcel`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
        body: JSON.stringify(request),
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function GetFilesAndLogs_Abs(demandeId) {
    const response = await fetch(`${apiUrl}/api/values/GetFilesAndLogs?demandeId=${demandeId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        }
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function GetDashboard(email) {
    const response = await fetch(`${apiUrl}/api/values/Dashboard?username=${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        }
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function GetDemandsFromDashboard(email, status) {
    // status can be "Demande traitée", "Demande en cours" or "Demande initiale"
    const response = await fetch(`${apiUrl}/api/values/DemandsFromDashboard?username=${email}&status=${status}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        }
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }
    return response;
}

export async function SendContactMessage(request) {
    const response = await fetch(`${apiUrl}/api/messages/ContactForm`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
        body: JSON.stringify(request),
    });

    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }

    return response;
}

export async function UpdateStatusAbs(request) {
    const response = await fetch(`${apiUrl}/api/values/UpdateStatusSuppAsk`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
        body: JSON.stringify(request),
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }

    return response;
}

export async function UpdateStatusInDentsuDb(request) {
    const response = await fetch(`${apiUrl}/api/dentsu/UpdateStatusInDb`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
        body: JSON.stringify(request),
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }

    return response;
}

export async function saveDentsuJsonFile(request) {
    console.log(`${apiUrl}/api/dentsu/SaveJsonFile`)
    console.log(request)
    const response = await fetch(`${apiUrl}/api/dentsu/SaveJsonFile`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'apikey': apikey,
        },
        body: JSON.stringify(request),
    });
    if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
    }

    return response;
}
