import React, { useState } from 'react';
import styles  from './Card.module.css';
import subtract from '../../assets/Subtract.svg';
import xls from '../../assets/Xls.svg';
import folder from '../../assets/folder.png';
import { ExportExcel, GetFilesAndLogsDentsu, UpdateStatusAbs } from "../../Services/demandes";
import JSZip from 'jszip';
import updateIcon from '../../assets/greenUpdateIcon.svg'
import newIcon from '../../assets/newIcon.svg'
import eyeIcon from '../../assets/oeil.png'
import PopupAlert from '../PopUp/PopupAlert/PopupAlert';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate
import See from '../AnimIcons/See/See';
import DownloadExcelFile from '../AnimIcons/DownloadExcelFile/DownloadExcelFile';
import DownloadFolder from '../AnimIcons/DownloadFolder/DownloadFolder';

function Card ({ key, vendorLib, demandeId, date, status, type, vendorId, vendorType, chatOpen, demandeur, details }) {
const [statut, setStatut] = useState(status); // valeur initiale
const [messageAlert, setMessageAlert] = useState("");
const [showPopupAlert, setShowPopupAlert] = useState("");
const [xlsloadstate, setXlsLoadstate] = useState("")
const [loadstate, setLoadState] = useState("")
const [loadDetailState, setLoadDetailState] = useState("")
const navigate = useNavigate();

const exportXls = async ()  => {
  let contexte = "";
  if(!sessionStorage.getItem("currentMail").includes("dentsu")){
    contexte = "abs";
  }else{
    contexte = "dentsu";
  }
  setXlsLoadstate("loading")
  let request = {
    user: {
        email: sessionStorage.getItem("currentMail")
    },
    demandes:[
      {
        id:demandeId,
        vendorLib:vendorLib,
        status:status,
        detailslist:details,
        dateinit:date,
        demandeur:demandeur,
        objet:type, 
      }
    ]
};

  const response = await ExportExcel(request, 'dentsu');

  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    throw new Error(message);
    setXlsLoadstate("")

  }

  const data = await response.json();
  const result = JSON.parse(data);
  let fichierEnBase64 = result.results.fileslist[0].filecontent
  let filename = result.results.fileslist[0].filename
  let mimetype = result.results.fileslist[0].mimetype 
  
downloadFile(fichierEnBase64, filename, mimetype);
setXlsLoadstate("loaded")
}

const downloadFile = (fichierEnBase64, filename, mimetype) => {
  const file = fileBuilt(fichierEnBase64, mimetype)
// Download du fichier
  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
};

const fileBuilt = (fichierEnBase64, mimetype) => {
    // Création du fichier
    let filecontent = atob(fichierEnBase64)
    var buffer = new ArrayBuffer(filecontent.length);
    var bufferView = new Uint8Array(buffer);
    
    for (var i = 0; i < filecontent.length; i++) {
      bufferView[i] = filecontent.charCodeAt(i);
    }
    const file = new Blob([bufferView], {type: mimetype}); 
  return file;
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleDateString('fr-FR', options);
}

const handleClosePopup = () => {
  setShowPopupAlert(false)
  setMessageAlert("")
}

const downloadFolder = async ()  => {
  setLoadState("loading")
  const response = await GetFilesAndLogsDentsu(demandeId);

  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    setLoadState("")
    throw new Error(message);
  }
  const data = await response.json();
  const result = JSON.parse(data);
  let files = result.results.fileslist;

  let zip = new JSZip();
  
  // Ajouter chaque fichier au ZIP
  files.forEach(fichier => {
    let fich = fichier.filecontent;

    if (fichier.filecontent.includes(";base64,")){
      let arrayBase64 = fichier.filecontent.split(";base64,");
      fich = arrayBase64[1]
    }

    zip.file(fichier.filename, fich, { base64: true });
  });

  // Générer le contenu du ZIP
  zip.generateAsync({ type: "blob" })
    .then(function (blob) {
      // Créer un objet URL pour le blob
      let url = URL.createObjectURL(blob);

      // Créer un lien pour télécharger le ZIP
      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Logs_Demande'+ demandeId +'.zip');

      // Ajouter le lien à la page et cliquer dessus pour déclencher le téléchargement
      document.body.appendChild(link);
      link.click();
    });

    setLoadState("loaded")

}


const updateStatus = async (event) => {
  let newValue = event.target.value

  const newStatus = newValue;
  setStatut(newStatus);


  let request = {
    user: {
        email: sessionStorage.getItem("currentMail")
    },
    demande:
    {
      id:demandeId,
      status:event.target.value,

    }    
  };

  const response = await UpdateStatusAbs(request);

  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    
    setMessageAlert("Echec de la mise à jour du statut " + newValue)
    setShowPopupAlert(true)

    throw new Error(message);
  }
  else{
    setMessageAlert("Statut mis à jour. Nouveau statut : " + newValue)
    setShowPopupAlert(true)

    let statusElement = document.getElementById("status")
    statusElement.value = newValue;
  }

}

  return (
    <div className={styles.container}>

      {showPopupAlert && <PopupAlert message={messageAlert} handleClosePopup={handleClosePopup} />}
      
      {/** PREMIERE LIGNE */}
      <div className={styles.topTicket}> 

        <div className={styles.topLeftTicket}>

          {/** GAUCHE */}
          <div className={styles.numeroDemande}>
            <p>              
              { type === 'Update' && <img src={updateIcon}/> }
              { type === 'Création' && <img src={newIcon}/> }

              Demande #<span id="number">{demandeId}</span> 

            </p>
          </div>

        </div>

        {/** DROITE */}

        <div className={styles.iconContainer}>

          <div className={styles.folderImg}>
            <DownloadFolder  loadState={loadstate}  onclick={downloadFolder}/>
          </div>

          {status !== "Structural Error" && (
          <div className={styles.xlsImg}>
            <DownloadExcelFile  loadState={xlsloadstate}  onclick={exportXls}/>
          </div>)}

          <div className={styles.eyeIcon}>
            <See loadState={loadDetailState} onclick={chatOpen}/>
          </div>

        </div>




{/*}        <div className={styles.iconContainer}>
          {status !== "Structural Error" && (<div>
            <img className={styles.xlsImg} src={xls} alt="Export excel" onClick={exportXls} />
          </div>
          )}
          <img className={styles.folderImg} src={folder} alt="Download folder" onClick={downloadFolder} />
          <img className={styles.eyeIcon} src={eyeIcon} alt="Display"  onClick={chatOpen} />

        </div>*/}

      </div>

      {/** DEUXIEME LIGNE */}
      <div id="name" className={styles.name}>
        {vendorLib}<p className={styles.vendorData}>[ vendor {vendorId} : {vendorType} ]</p>
      </div>

        {/*<p id="countEmail" hidden>{countEmail}</p>*/}
      <hr></hr>


      <div className={styles.bottomTicket}>
        <p>{formatDate(date)}</p>

        <p className={styles.status}>Statut : {status}</p>

        {/*<select className={styles.status} id="status" name="status" onChange={updateStatus} value={statut}>
          <option value="Demande initiale">Demande initiale</option>
          <option value="Demande en cours">Demande en cours</option>
          <option value="Demande traitée">Demande traitée</option>
        </select>*/}

      <p>{demandeur}</p>

      </div>
    </div>
  );
};

export default Card;
