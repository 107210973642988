import DocType from './DocType';

class Document {
  constructor(data) {
    this.DocType = data.DocType;
    this.FileName = data.FileName;
    this.FileType = data.FileType;
    this.base64FileContent = data.base64FileContent;
  }
}

export default Document;
