import Request from './Request';

class Root {
  /*constructor(request) {
    this.request = request.map(req => new Request(req));
  }*/
  constructor(Request) {
    // Vérifie si requests est un tableau, sinon initialise avec un tableau vide
    this.Request = Array.isArray(Request) ? Request.map(req => req) : [];
  }
}

export default Root;