import Type from './Type';
import Country from './Country';

class Address {
  constructor(data) {
    this.Id = data.Id;
    this.ExternalId = data.ExternalId;
    this.Type = data.Type;
    this.Default = data.Default;
    this.Company = data.Company;
    this.Adresse1 = data.Adresse1;
    this.Adresse2 = data.Adresse2;
    this.ZipCode = data.ZipCode;
    this.City = data.City;
    this.Country = data.Country;
  }
}

export default Address;
