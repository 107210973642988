import React, { useEffect, useState, useRef } from "react";
import styles from "./AskPage.module.css";
import Button from "../Button/Button";
import { NewDemand, testdemande } from '../../Services/demandes';
import cancelIcon from "../../assets/cancel.png";
import cross_svg from "../../assets/cross.svg";
import { useNavigate } from 'react-router-dom'; // Importez useNavigate
import WithIdent from "../HOC/WithIdent";
import PopupAlert from "../PopUp/PopupAlert/PopupAlert";

function AskPage({ onPageChange }) {
    const [email, setEmail] = useState(sessionStorage.getItem('currentMail'));
    const [regiesupportlib, setRegiesupportlib] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [dataLogin, setDataLogin] = useState(sessionStorage.getItem('dataLogin'));
    const [files, setFiles] = useState([]);
    const dropzoneRef = useRef(null);
    const [messageAlert, setMessageAlert] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [currentPage, setCurrentPage] = useState('home');
    const [objetError, setObjetError] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const navigate = useNavigate();

    const handleEmailChange = (event) => setEmail(event.target.value);
    const handleRegiesupportlibChange = (event) => setRegiesupportlib(event.target.value);
    const handleSubjectChange = (event) => {
        const value = event.target.value;
        setSubject(value);
        if (value !== "" && objetError) {
            setObjetError(false);
        }
    };
    const handleBodyChange = (event) => setBody(event.target.value);

    const handleCloseDemand = () => {
        console.log("dans handle close pop up")
        setMessageAlert('')
        setShowPopup(false);
        navigate('/');
    }

    const handleSendClick = async () => {

        let objet = document.getElementById("objet").value;
        if (objet === "") {
            setObjetError(true);
            return;
        }
        setObjetError(false);

        setButtonLoading(true)
        try {
            const fichiers = document.querySelector('input[type="file"][multiple]');
            NewDemand(email, regiesupportlib, subject, body, files)
                .then(resp => {
                    if (!resp.ok) {
                        setMessageAlert('Une erreur est survenue')
                        setShowPopup(true);
                        const messages = `An error has occurred: ${resp.status}`;
                        throw new Error(messages);
                    }
                    return resp.json();
                })
                .then(data => {

                    setButtonLoading(false)
                    const result = JSON.parse(data);

                    if (result.status.code === 200 && result.status.message.toString() === "OK") {
                        setMessageAlert("Nous avons bien reçu votre demande.")
                    } else if (result.status.code === 200 && result.status.message.includes("EmailError")) {
                        setMessageAlert("Demande bien enregistrée ; une erreur est survenue lors de la notification.")
                    } else if(result.status.code === 422){
                        setMessageAlert("Une erreur est survenue lors de l'enregistrement de la demande.")
                    }

                    setShowPopup(true);
                    setRegiesupportlib('');
                    setSubject('');
                    setBody('');
                    setFiles([]);
                })
        } catch (error) {
            setButtonLoading(false)
            setMessageAlert("Une erreur est survenue lors de l'enregistrement de la demande : " + error)
            setShowPopup(true)
            console.error(error.message);
        }
    };

    useEffect(() => {

    }, []);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropzoneRef.current) {
            dropzoneRef.current.style.border = '2px dashed cyan';
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropzoneRef.current) {
            dropzoneRef.current.style.border = '2px dashed #ccc';
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropzoneRef.current) {
            dropzoneRef.current.style.border = '2px dashed #ccc';
        }

        const droppedFiles = Array.from(e.dataTransfer.files);
        handleFiles(droppedFiles);
    };

    const handleFiles = (filesArray) => {
        const fileReaders = [];
        const fileList = [];

        filesArray.forEach(file => {
            const reader = new FileReader();
            fileReaders.push(reader);

            reader.onload = (e) => {
                const base64Content = e.target.result.match(/,(.*)$/)[1]; // Extract base64 content after comma
                fileList.push({ filename: file.name, filecontent: base64Content });
                if (fileList.length === filesArray.length) {
                    setFiles(prevFiles => [...prevFiles, ...fileList]);
                }
            };
            reader.readAsDataURL(file); // Read the file as Data URL to get base64 encoded string
        });
    };

    const dataLoginItems = dataLogin
        ? dataLogin.split(/[,]+/).map((item, index) => <li key={index}>{item}</li>)
        : [];

    return (
        <div className={styles.root}>
            <div className={styles.pageTitle}>
                <h2>Nouvelle demande</h2>
            </div>
            <div className={styles.container}>
                <div className={styles.squareContainer}>
                    <div className={styles.square}>
                        <div>
                            { dataLogin.trim() !== "" &&
                            (<div className={styles.divElementLeft}>
                                <h4>Pour rappel, les documents associés pour une création de régie :</h4>
                                <ul className={styles.listeDoc}>
                                    {dataLoginItems}
                                </ul>
                            </div>)}
                            <div className={styles.divElementLeft}>
                                <p>Email</p>
                                <input style={{ background: 'lightcyan' }} value={email} type="email" id="email" name="email" required onChange={handleEmailChange} readOnly />
                            </div>
                            <div className={styles.divElementLeft}>
                                <p>Objet</p>
                                <select id="objet" name="objet" required onChange={handleSubjectChange}>
                                    <option value="">Sélectionner</option>
                                    <option value="Ajout d'un support à une régie existante">Ajout support régie existante</option>
                                    <option value="Création de fournisseur">Création de régie</option>
                                    <option value="Modification de coordonnées bancaires">Modification / Ajout de coordonnées bancaires</option>
                                    <option value="Modification de lien support / régie">Modification lien support / régie</option>
                                    <option value="Autre">Autre</option>
                                </select>
                                {objetError && <p style={{ color: 'red' }}>Veuillez sélectionner un objet</p>}
                            </div>
                            <div className={styles.divElementLeft}>
                                <p>Régie</p>
                                <input type="text" id="regie" name="regie" required onChange={handleRegiesupportlibChange} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.square}>
                        <div className={styles.divElementRight}>
                            <p>Votre message</p>
                            <textarea id="message" name="message" required onChange={handleBodyChange} />
                        </div>

                        <div className={styles.dragAndDrop}>
                            <p>Fichiers (2 mo max par document)</p>

                            <div
                                className={styles.dropzone}
                                ref={dropzoneRef}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                            >
                                <input
                                    type="file"
                                    onChange={(e) => handleFiles(Array.from(e.target.files))}
                                    multiple
                                />

                                <ul className={styles.filesList}>
                                    {files.map((file, index) => (
                                        <li key={index}>
                                            <span className={styles.fileName}>{file.filename}</span>
                                            <img src={cancelIcon} alt="annuler" className={styles.cancelFile} onClick={() => setFiles(files.filter((_, i) => i !== index))} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Button text="Envoyer" onClick={handleSendClick} loading={buttonLoading} />
            </div>

            {showPopup && (
                <PopupAlert message={messageAlert} handleClosePopup={handleCloseDemand}/>
                /*<div className={styles.popup}>
                    <span className={styles.closeIcon} onClick={handleCloseDemand}>
                        <img src={cross_svg} alt="Close" className={styles.closeIconImg} />
                    </span>
                    <p>Demande envoyée</p>
                </div>*/
            )}
        </div>
    );
}

export default WithIdent(AskPage);
