import Country from './Country';
import APE from './APE';
import Regimesocial from './Regimesocial';

class Register {
  constructor(data) {
    this.Country = data.Country;
    this.SerialNumber = data.SerialNumber;
    this.LegalName = data.LegalName;
    this.VATNumber = data.VATNumber;
    this.LegalStatus = data.LegalStatus;
    this.Capital = data.Capital;
    this.RCS = data.RCS;
    this.APE = data.APE;
    this.Regimesocial = data.Regimesocial;
    this.NumeroSecuriteSocial = data.NumeroSecuriteSocial;
  }
}

export default Register;
