import React, { useRef, useEffect, useState } from "react";
import styles from "./PopUpConnexion.module.css";
import Button from "../Button/Button";
import { CheckConnectionHigh } from '../../Services/identification_api';
import cross_svg from "../../assets/cross.svg"; // Assurez-vous que le chemin vers votre fichier cross.svg est correct
import PopupAlert from "./PopupAlert/PopupAlert";
import { useNavigate } from 'react-router-dom'; // Importez useNavigate

function PopUpConnexion({ onClose, pageChange }) {
  const popUpRef = useRef();
  const navigate = useNavigate(); // Obtenez la fonction de navigation avec useNavigate
  const [email, setEmail] = useState('');
  const [mdp, setMdp] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [showPopupAlert, setShowPopupAlert] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleMdpChange = (event) => {
    setMdp(event.target.value);
  }

  const handleClosePopup = () => {
    setShowPopupAlert(false);
    setMessageAlert("");
  }

  const getEmailRole = (mail) => {
          
      if (mail.endsWith('dentsu.com')) {
        sessionStorage.setItem('role', 'dentsu');
      } 
      else if (mail.endsWith('media-servicing.com')) {
        sessionStorage.setItem('role', 'media-servicing');
      } 
      else {
        sessionStorage.setItem('role', 'other');
      }
      
    };

  const handleConnexion = async () => {

    setButtonLoading(true)
    let mail = document.getElementById("email").value;
    let pwd = document.getElementById("mdp").value;

    try {
      const response = await CheckConnectionHigh(mail, pwd);
      setButtonLoading(false)

      const data = await response.json();
      const code = JSON.parse(data);

      if (code.status.code === 200) {
        sessionStorage.setItem('i','0d15924c-e5eb-4acb-b380-5d65a2fee207' )
        sessionStorage.setItem('currentMail', mail);
        getEmailRole(mail);
        setButtonLoading(false)
        onClose();
        
        if(mail.endsWith("dentsu.com")){
          navigate('/demandeCarat');
        }
        else {
          navigate('/search');
        }  

      } else {

        setButtonLoading(false)
        setMessageAlert("Échec lors de l'authentification");
        setShowPopupAlert(true);
      }
    } catch (error) {
      console.error(error.message);
      setButtonLoading(false)
      setMessageAlert("Erreur de connexion");
      setShowPopupAlert(true);
    } finally{
      setButtonLoading(false)
    }
  }

  useEffect(() => {
  }, [messageAlert, showPopupAlert]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();  // Pour éviter le rechargement de la page
        handleConnexion();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);
    document.addEventListener("keydown", handleEnterKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
      document.removeEventListener("keydown", handleEnterKey);
    };
  }, [onClose]);

  return (
    <div className={styles.contenantPopup}>
      {showPopupAlert && <PopupAlert message={messageAlert} handleClosePopup={handleClosePopup} />}
      {!showPopupAlert && (
        <div className={styles.popUp} ref={popUpRef}>
          <img
            src={cross_svg}
            alt="Fermer"
            className={styles.closeIcon}
            onClick={onClose}
          />
          <h1 className={styles.h1}>Espace réservé</h1>
          <p>Merci de saisir vos identifiants</p>
          <div className={styles.inputDiv}>
            <form>
              <label htmlFor="email"></label>
              <input
                className={styles.input}
                type="email"
                placeholder="Email"
                id="email"
                name="email"
                required
                onChange={handleEmailChange}
              />
              <label htmlFor="mdp"></label>
              <input
                className={styles.input}
                type="password"
                placeholder="Mot de passe"
                id="mdp"
                name="mdp"
                required
                onChange={handleMdpChange}
              />
            </form>
          </div>
          <div className={styles.divButton}>
            <Button text="Se connecter" onClick={handleConnexion} loading={buttonLoading}/>
          </div>
        </div>
      )}
    </div>
  );
}

export default PopUpConnexion;
