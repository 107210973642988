import React, { useState } from "react";
import styles from "./DownloadFolder.module.css";
import folder from "../../../assets/folder.png"
import ok from "../../../assets/verifier.png"

function DownloadFolder({loadState, onclick}) {


  return (
    <div className={styles.container} onClick={onclick}>
        <div className={styles.zindex0} >
          <img src={folder} alt="télécharger le dossier" className={`${loadState === "loading" ? styles.transparent : ''}`}/>
        </div>
        
        {loadState === "loaded" && <img src={ok} alt="dossier téléchargé" className={styles.zindex1}/> }

        {loadState === "loading" && (
        <div className={styles.center}>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
            <div className={styles.wave}></div>
        </div>
        )}

    </div>

  );
}

export default DownloadFolder;
